export interface UserAddressModel {
  id: number;
  name?: string;
  address: string;
  longitude: number;
  latitude: number;
  details?: string;
  place?: string;
  room?: string;
  floor?: string;
  addressType: UserAddressType;
  lastUsedDate?: string;
  city?: string;
  postalCode?: string;
  range?: number;
  country?: string;
  clientHotel?: boolean;
  clientHome?: boolean;
  events?: boolean;
  state?: string;
  privateAddress?: string;
  privateLongitude?: number;
  privateLatitude?: number;
  circleLatitude: number;
  circleLongitude: number;
  polygonCoordinates?: google.maps.LatLngLiteral[];
  regions: number[];
  allRegions: boolean;
}

export enum UserAddressType {
  HOME,
  HOTEL,
  EVENT,
  WORK_PUBLIC,
  WORK_PRIVATE,
  WORK_PICKUP,
  WORK_RANGE,
}
