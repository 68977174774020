/**
 * Calculates the tax included in the total amount.
 * @param {number} totalAmount - The total amount including tax.
 * @param {number} taxRate - The tax rate as a percentage (e.g., for 20%, pass 20).
 * @returns {number} - The amount of tax included in the total amount.
 */
export function calculateIncludedTax(
  totalAmount: number,
  taxRate: number
): number {
  if (totalAmount <= 0 || taxRate <= 0) {
    return 0;
  }

  // Convert tax rate to a decimal
  const taxRateDecimal = taxRate / 100;

  // Calculate the tax amount
  return +(totalAmount * taxRateDecimal).toFixed(3);
}

export function calculateAmountBeforeTax(
  totalAmount: number,
  taxRate: number
): number {
  if (totalAmount <= 0 || taxRate <= 0) {
    return 0;
  }

  // Convert tax rate to a decimal
  const taxRateDecimal = (100 - taxRate) / 100;

  // Calculate the tax amount
  return +(totalAmount * taxRateDecimal).toFixed(3);
}
