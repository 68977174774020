export interface ToastModel {
  message: string;
  type: ToastType;
  timeout: number;
  redirectLink?: string;
  redirectParams?: any;
}

export enum ToastType {
  Success,
  Info,
  Error,
  Warning,
}
