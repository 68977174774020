import { Injectable, signal } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastModel, ToastType } from '@app/shared/models/toast.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  toastList = signal<ToastModel[]>([]);

  /**
   * Obsolete. Use addToast instead.
   * @param title
   * @param message
   */
  public success(title: string, message: string): Observable<void> {
    this.addToast({
      message: message,
      type: ToastType.Success,
      timeout: 5000,
    });
    return of();
  }

  /**
   * Obsolete. Use addToast instead.
   * @param title
   * @param message
   */
  public error(title: string, message: string): Observable<void> {
    this.addToast({
      message: message,
      type: ToastType.Error,
      timeout: 5000,
    });
    return of();
  }

  /**
   * Obsolete. Use addToast instead.
   * @param title
   * @param message
   */
  public warn(title: string, message: string): Observable<void> {
    this.addToast({
      message: message,
      type: ToastType.Warning,
      timeout: 5000,
    });
    return of();
  }

  /**
   * Obsolete. Use addToast instead.
   * @param title
   * @param message
   */
  public info(title: string, message: string): Observable<void> {
    this.addToast({
      message: message,
      type: ToastType.Info,
      timeout: 5000,
    });
    return of();
  }

  public addToast(toast: ToastModel) {
    this.toastList.update((e) => [...e, toast]);
    if (toast.timeout) {
      setTimeout(() => this.removeToast(toast), toast.timeout);
    }
  }

  public removeToast(toast: ToastModel) {
    const index = this.toastList().indexOf(toast);
    if (index > -1) {
      this.toastList.update((e) => {
        e.splice(index, 1);
        return e;
      });
    }
  }
}
