import { filter, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../../shared/services';
import { select, Store } from '@ngrx/store';
import { getUser } from '@app/shared/reducers/user.selectors';
import { User } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard  {
  constructor(
    private router: Router,
    private userService: UserService,
    private store: Store<any>
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(getUser),
      filter((user) => user !== undefined),
      map((user: User) => {
        return !user;
      })
    );
  }
}
