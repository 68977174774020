export class MapStyles {
  day: Array<any> = [
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dc3232',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#444444',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'all',
      stylers: [
        {
          color: '#bc36a8',
        },
        {
          weight: '0.01',
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'geometry.stroke',
      stylers: [
        {
          weight: '1.00',
        },
        {
          color: '#b21a9d',
        },
        {
          gamma: '1.67',
        },
      ],
    },
    {
      featureType: 'administrative.province',
      elementType: 'all',
      stylers: [
        {
          color: '#dc1010',
        },
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.province',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          weight: '0.01',
        },
      ],
    },
    {
      featureType: 'administrative.province',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'all',
      stylers: [
        {
          color: '#f39fff',
        },
        {
          gamma: '1.00',
        },
        {
          weight: '0.01',
        },
      ],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#e970f9',
        },
      ],
    },
    {
      featureType: 'administrative.neighborhood',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          color: '#f2f2f2',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#f8e8fb',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
        {
          weight: '0.01',
        },
      ],
    },
    {
      featureType: 'poi.attraction',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.business',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.medical',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi.medical',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#fadef8',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.place_of_worship',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.place_of_worship',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.school',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.school',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#e1d8e1',
        },
      ],
    },
    {
      featureType: 'transit.station.airport',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'transit.station.airport',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'transit.station.airport',
      elementType: 'labels.text',
      stylers: [
        {
          color: '#ff00f4',
        },
        {
          weight: '0.01',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#f6beff',
        },
      ],
    },
  ];

  night: Array<any> = [
    {
      featureType: 'all',
      elementType: 'geometry',
      stylers: [
        {
          color: '#bb12f2',
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        {
          gamma: 0.01,
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          saturation: -31,
        },
        {
          lightness: -33,
        },
        {
          weight: 2,
        },
        {
          gamma: 0.8,
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#ffd740',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels.text',
      stylers: [
        {
          weight: '0.01',
        },
        {
          saturation: '79',
        },
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'administrative.province',
      elementType: 'all',
      stylers: [
        {
          weight: '0.01',
        },
      ],
    },
    {
      featureType: 'administrative.province',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.province',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
        {
          weight: '0.38',
        },
      ],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'all',
      stylers: [
        {
          weight: '0.01',
        },
      ],
    },
    {
      featureType: 'administrative.neighborhood',
      elementType: 'all',
      stylers: [
        {
          weight: '0.01',
        },
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'all',
      stylers: [
        {
          weight: '0.01',
        },
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text',
      stylers: [
        {
          weight: '0.01',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          lightness: 30,
        },
        {
          saturation: 30,
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'all',
      stylers: [
        {
          color: '#b21a9d',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          saturation: 20,
        },
      ],
    },
    {
      featureType: 'poi.attraction',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.attraction',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.business',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.business',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.government',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.government',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          lightness: 20,
        },
        {
          saturation: -20,
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels',
      stylers: [
        {
          weight: '0.01',
        },
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.place_of_worship',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
        {
          weight: '0.01',
        },
      ],
    },
    {
      featureType: 'poi.place_of_worship',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.school',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.school',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          lightness: 10,
        },
        {
          saturation: -30,
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [
        {
          saturation: 25,
        },
        {
          lightness: 25,
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [
        {
          weight: '0.01',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#5d0055',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'transit.station.rail',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit.station.rail',
      elementType: 'labels',
      stylers: [
        {
          weight: '0.01',
        },
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          lightness: -20,
        },
        {
          color: '#5d0055',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ];
}

export class MapClustererStyles {
  day: Array<any> = [
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/m1_day.svg',
      height: 50,
      width: 35,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/m2_day.svg',
      height: 50,
      width: 35,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/m3_day.svg',
      height: 50,
      width: 35,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/m4_day.svg',
      height: 50,
      width: 35,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
  ];
  night: Array<any> = [
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/m1_night.svg',
      height: 50,
      width: 35,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/m2_night.svg',
      height: 50,
      width: 35,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/m3_night.svg',
      height: 50,
      width: 35,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/m4_night.svg',
      height: 50,
      width: 35,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
  ];
}

export class MapBrothelClustererStyles {
  day: Array<any> = [
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/e1_day.svg',
      height: 55,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 55,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/e2_day.svg',
      height: 55,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 55,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/e3_day.svg',
      height: 55,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 55,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/e4_day.svg',
      height: 55,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 55,
    },
  ];
  night: Array<any> = [
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/e1_night.svg',
      height: 55,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 55,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/e2_night.svg',
      height: 55,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 55,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/e3_night.svg',
      height: 55,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 55,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/e4_night.svg',
      height: 55,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 55,
    },
  ];
}

export class MapAgencyClustererStyles {
  day: Array<any> = [
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/agency_cluster_day.svg',
      height: 50,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/agency_cluster_day.svg',
      height: 50,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/agency_cluster_day.svg',
      height: 50,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/agency_cluster_day.svg',
      height: 50,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
  ];
  night: Array<any> = [
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/agency_cluster_night.svg',
      height: 50,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/agency_cluster_night.svg',
      height: 50,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/agency_cluster_night.svg',
      height: 50,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
    {
      textColor: 'white',
      url: '/assets/images/maps/markers/agency_cluster_night.svg',
      height: 50,
      width: 50,
      textSize: 11,
      fontWeight: 'bold',
      textLineHeight: 50,
    },
  ];
}

export class MapInstaStyles {
  day: Array<any> = [
    {
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
  ];

  night: Array<any> = [
    {
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
  ];
}
