export const dialogMessages = {
  client: {
    cancel: {
      estimatedArrivalExceeded:
        'The client is no longer on their way and the date has been cancelled',
      confirmArrivalExceeded:
        'The client has not confirmed their arrival and the date has been cancelled',
      direction: 'The client has cancelled the date.',
      confirmArrival:
        'You have cancelled your date and we will review the reason why. If required, we will contact you. Thank you.',
    },
    endEarly: {
      triggerFromClient: {
        reportIncident:
          'Thank you for reporting your incident we will investigate it and get back to you as soon as possible.',
      },
      triggerFromGingr: {
        cancel: 'The gingr has cancelled the date.',
        reportIncident:
          'The gingr has reported an incident. As soon as we have reviewed it, we will send you a confirmation of the outcome.',
      },
    },
  },
  gingr: {
    cancel: {
      estimatedArrivalExceeded:
        'The client is no longer on their way and the date has been cancelled',
      startClockExceeded:
        'The Gingr is offline and the date has been cancelled',
      exit: 'The Gingr exited',
      alreadyBooked: 'The Gingr  was already booked',
      direction: 'The gingr has cancelled the date.',
      confirmArrival:
        'The client has cancelled  your date and we will review the reason why. If required, we will contact you. Thank you.',
    },
    finished: {
      confirmExtended:
        'The client is offline and the date has been ended. You can still rate your date in your Bookings section',
    },
    endEarly: {
      triggerFromClient: {
        cancel: 'The gingr has cancelled the date.',
        reportIncident:
          'The client has reported an incident. As soon as we have reviewed it, we will send you a confirmation of the outcome.',
      },
      triggerFromGingr: {
        reportIncident:
          'Thank you for reporting your incident we will investigate it and get back to you as soon as possible.',
      },
    },
    bookedGingr: 'This Gingr has just been booked!',
  },
};
