import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { STORAGE_NAMES } from '@app/instafeature/models/constraints';
import { Store } from '@ngrx/store';
import { isUserClient } from '@app/shared/reducers/user.selectors';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ConversationRequestType } from '@app/shared/models/conversation/conversation-request';
import { CommonService } from '@app/instafeature/services/common.service';
import { ConversationService } from '@app/shared/services/conversation.service';
import { Filter } from '@app/instafeature/models/filter';
import {
  deleteActiveBooking,
  getInstaState,
  saveUserConnected,
} from '@app/instafeature/store/instafeature.actions';
import { InstafeatureFilterService } from '@app/instafeature/services/instafeature-filter.service';
import { StorageService } from '@app/instafeature/services/storage.service';
import { L10nTranslationService } from 'angular-l10n';
import { LocationService } from '@app/shared/services';
import { getCurrentUserAction } from '@app/shared/actions/shared.actions';

@Injectable({
  providedIn: 'root',
})
export class BookingStateService {
  readonly translation = inject(L10nTranslationService);

  isClient: boolean;

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private store: Store,
    public dialog: MatDialog,
    public commonService: CommonService,
    private conversationService: ConversationService,
    public filterService: InstafeatureFilterService,
    private storageService: StorageService,
    private locationService: LocationService
  ) {
    this.store
      .select(isUserClient)
      .subscribe((isClient) => (this.isClient = isClient));
  }

  cancelBooking(instaStateId: number, reason: string, partnerId: number) {
    this.commonService
      .cancelBooking(instaStateId, {
        reason: reason,
        comment: '',
      })
      .pipe(
        switchMap((response) => {
          if (!response) {
            return of(null);
          }
          this.store.dispatch(getCurrentUserAction());
          return this.commonService.canceledBookingEvent(
            instaStateId,
            partnerId
          );
        }),
        switchMap((response) => {
          if (!response) {
            return of(null);
          }
          return this.conversationService.endConversation({
            instanceId: instaStateId,
            instanceType: ConversationRequestType.INSTA_SESSION,
          });
        })
      )
      .subscribe((response) => {
        if (!response) {
          return;
        }
        this.router.navigate(['/instafeature', 'booking', 'finish'], {
          state: {
            message: 'INSTA.you cancelled date',
            title: 'INSTA.date cancelled',
            cancel: true,
          },
        });
      });
  }

  closeInstaSession(redirectPath = '/gingrs', refreshInstaState = false) {
    this.resetStateAndNavigate(redirectPath);
    this.commonService.closeSession().subscribe(() => {
      if (refreshInstaState) {
        this.store.dispatch(getInstaState({ skipRedirect: true }));
      }
    });
  }

  resetStateAndNavigate(redirectPath = '/gingrs') {
    this.resetState();
    this.router.navigate([redirectPath]);
  }

  resetState() {
    this.filterService.filter = new Filter();
    this.storageService.delete(STORAGE_NAMES.FILTER);
    this.store.dispatch(deleteActiveBooking());
    this.store.dispatch(saveUserConnected({ userConnected: false }));
    this.locationService.stopWatchUserLocation();
  }

  resetStateAndStayOnInsta() {
    this.filterService.filter = new Filter();
    this.storageService.delete(STORAGE_NAMES.FILTER);
    this.store.dispatch(deleteActiveBooking());
    this.locationService.stopWatchUserLocation();
  }
}
