import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  ls = localStorage;
  ss = sessionStorage;
  lastSeenStorage = 'lastSeen';

  constructor() {}

  set(name: string, value: any, session?: boolean) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }

    if (session) {
      this.ss.setItem(name, value);
    } else {
      this.ls.setItem(name, value);
    }
  }

  get(name: string, session?: boolean): any {
    let value = session ? this.ss.getItem(name) : this.ls.getItem(name);

    if (!value) {
      return false;
    }

    if (value === 'undefined') {
      return false;
    }

    try {
      if (JSON.parse(value)) {
        value = JSON.parse(value);
      }
    } catch (e) {}

    return value;
  }

  delete(name: string, session?: boolean) {
    if (session) {
      this.ss.removeItem(name);
    } else {
      this.ls.removeItem(name);
    }
  }

  clear() {
    this.ls.clear();
  }

  unshift(name: string, value: any, session?: boolean) {
    let arr = this.get(name, session);
    if (!(arr instanceof Array)) {
      arr = [];
    }

    // remove when user visit the same user
    if (name === this.lastSeenStorage) {
      arr = arr.filter((element) => element.id !== value.id);
    }

    if (arr.length > 6 && name !== this.lastSeenStorage) {
      while (arr.length > 8) {
        arr.pop();
      }
      arr.unshift(value);
    } else {
      arr = [value, ...arr];
    }

    this.set(name, arr, session);
  }
}
