import { Filter } from './filter';
import { Pagination } from './pagination';

export class GetLoc {
  latitude: number;
  longitude: number;
  filter: Filter;
  pagination: Pagination;

  constructor() {
    this.filter = new Filter();
    this.pagination = new Pagination();
  }
}
