import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { UserService } from '@app/shared/services';
import { User, UserAvailableRoles, UserAvailableSex } from '@app/shared/models';
import { Subject, Subscription } from 'rxjs';
import { _t } from '@app/shared/helpers';
import { L10nTranslationService } from 'angular-l10n';
import { ConfigService } from '@app/shared/services/config.service';
import { select, Store } from '@ngrx/store';
import { getUser } from '@app/shared/reducers/user.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash-es';
import { OrderByPipe } from '../../pipes/orderby.pipe';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { RouterLink } from '@angular/router';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { NgFor } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
    selector: 'gingr-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        MatToolbarModule,
        NgFor,
        MatButtonModule,
        FlexModule,
        RouterLink,
        MatIconModule,
        ExtendedModule,
        OrderByPipe,
    ],
})
export class AccountMenuComponent implements OnInit, OnDestroy {
  private userBaseRole: string;
  private user: User;

  private destroy$ = new Subject<boolean>();

  public menuItems: Array<any>;

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private userService: UserService,
    private configService: ConfigService,
    private translate: L10nTranslationService,
    private store: Store<any>
  ) {
    iconRegistry.addSvgIconSetInNamespace(
      'account_menu',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/account_menu.symbol.svg'
      )
    );
  }

  ngOnInit() {
    this.store
      .pipe(
        select(getUser),
        filter((user) => user !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.userBaseRole = UserService.getUserBaseRole(user);
        this.user = _.cloneDeep(user);
        this.menuItems = this.initMenu(this.userBaseRole, this.user);
      });
  }

  initMenu(userBaseRole: string, user: User) {
    let additionalMenuItems = [];
    const baseMenuItems = [
      {
        label: this.translate.translate(_t('ACCOUNT.MENU.Bookings')),
        icon: 'account_menu:bookings',
        ordering: 0,
        routerLink: '/account/bookings',
      },
      {
        label: this.translate.translate(_t('ACCOUNT.MENU.Profile')),
        ordering: 2,
        icon: 'account_menu:profile',
        routerLink: '/account/profile',
      },
    ];
    additionalMenuItems = additionalMenuItems.concat(baseMenuItems);
    switch (userBaseRole) {
      case UserAvailableRoles.client.baseRoleName: {
        additionalMenuItems.push({
          label: this.translate.translate(_t('ACCOUNT.MENU.Account')),
          icon:
            user.sex === UserAvailableSex.female
              ? 'account_menu:female'
              : 'account_menu:male',
          ordering: 4,
          routerLink: '/account/dashboard',
        });
        break;
      }
      case UserAvailableRoles.gingr.baseRoleName: {
        additionalMenuItems.push(
          {
            label: this.translate.translate(_t('ACCOUNT.MENU.Account')),
            icon:
              user.sex === UserAvailableSex.female
                ? 'account_menu:female'
                : 'account_menu:male',
            ordering: 4,
            routerLink: '/account/dashboard',
          },
          {
            label: this.translate.translate(_t('ACCOUNT.MENU.Reviews')),
            ordering: 3,
            icon: 'account_menu:reviews',
            routerLink: '/account/reviews',
          }
        );
        break;
      }

      case UserAvailableRoles.agency.baseRoleName: {
        additionalMenuItems.push(
          {
            label: this.translate.translate(_t('ACCOUNT.MENU.Your Gingrs')),
            icon: 'account_menu:female',
            ordering: 5,
            routerLink: '/account/your-gingrs',
          },
          {
            label: this.translate.translate(_t('ACCOUNT.MENU.Account')),
            icon: 'account_menu:agency',
            ordering: 4,
            routerLink: '/account/dashboard',
          }
        );
        break;
      }

      case UserAvailableRoles.brothels.baseRoleName: {
        additionalMenuItems.push(
          {
            label: this.translate.translate(_t('ACCOUNT.MENU.Your Gingrs')),
            icon: 'account_menu:female',
            ordering: 5,
            routerLink: '/account/your-gingrs',
          },
          {
            label: this.translate.translate(_t('ACCOUNT.MENU.Account')),
            icon: 'account_menu:establishment',
            ordering: 4,
            routerLink: '/account/dashboard',
          }
        );
        break;
      }
    }
    return additionalMenuItems;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
