import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-redirect',
  template: '',
})
export class RedirectConfirmEmailComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    const fragment = this.route.snapshot.fragment;

    this.router.navigate(['/auth/verify'], { queryParams, fragment });
  }
}
