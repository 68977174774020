import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { GeocodingService } from '../services/geocoding.service';
import { SummaryCoordinates, SummaryLocation } from '@app/shared/models';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take } from 'rxjs/operators';
import {
  getUserLocationAction,
  storeUserLocationAction,
} from '@app/shared/actions/shared.actions';
import * as _ from 'lodash-es';
import { isBot } from '@app/shared/helpers/user-agent.helper';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class LocationService implements OnDestroy {
  private destroy$ = new Subject<boolean>();
  currentDeviceLocation = new BehaviorSubject<SummaryCoordinates>(null);
  lastGeocode: SummaryLocation;
  positionOptions: PositionOptions = {
    enableHighAccuracy: true,
    timeout: 8000,
    maximumAge: 1000,
  };
  watchId: number;

  constructor(
    private store: Store<any>,
    private geocoding: GeocodingService
  ) {
    if (isBot()) {
      const lat = 47.3769;
      const lng = 8.5417;
      this.currentDeviceLocation.next({
        latitude: lat,
        longitude: lng,
        isFallback: false,
      });
    }
  }

  initializeUserLocation(askAccess = false) {
    if (isBot()) {
      this.parseGeoLocationPosition(null);
      return;
    }

    if (this.watchId !== undefined) {
      return;
    }

    askAccess
      ? this.askForLocationAccess()
      : this.parseGeoLocationPosition(null);
  }

  async isLocationAccessGranted() {
    const granted = await navigator.permissions
      .query({ name: 'geolocation' })
      .then(function (PermissionStatus) {
        if (PermissionStatus.state === 'granted') {
          return true;
        }
      });
    if (granted) {
      this.store.dispatch(getUserLocationAction({ askAccess: true }));
    }

    return granted;
  }

  askForLocationAccess() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.parseGeoLocationPosition(position);
      },
      (err) => {
        Sentry.captureException(err);
        this.parseGeoLocationPosition(null);
      },
      this.positionOptions
    );
  }

  watchUserLocation() {
    if (isBot() || this.watchId !== undefined) {
      return;
    }

    this.watchId = navigator.geolocation.watchPosition(
      (position) => {
        this.parseGeoLocationPosition(position);
      },
      () => this.parseGeoLocationPosition(null),
      this.positionOptions
    );
  }

  stopWatchUserLocation() {
    if (this.watchId !== undefined) {
      navigator.geolocation.clearWatch(this.watchId);
      this.watchId = undefined;
    }
  }

  parseGeoLocationPosition(position: GeolocationPosition) {
    const lastDeviceLocationKey = 'lastDeviceLocation';

    if (position?.coords) {
      sessionStorage.setItem(
        lastDeviceLocationKey,
        JSON.stringify({
          coords: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        })
      );
    } else {
      position = JSON.parse(sessionStorage.getItem(lastDeviceLocationKey));
    }

    // fallback to Zurich if it cannot get location
    // or location not requested yet
    const lat = position?.coords?.latitude || 47.3769;
    const lng = position?.coords?.longitude || 8.5417;
    this.currentDeviceLocation.next({
      latitude: lat,
      longitude: lng,
      isFallback: !position?.coords?.latitude,
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setUserPosition(position) {
    this.store.dispatch(
      storeUserLocationAction({
        data: this.getLocationFromGeocoderResult([position]),
      })
    );
  }

  getLocationFromGeocoderResult(
    results: google.maps.GeocoderResult[],
    isFallback?: boolean
  ): SummaryLocation {
    const add: string = results[0].formatted_address;
    const lat: number = results[0].geometry.location.lat();
    const lng: number = results[0].geometry.location.lng();
    const aC = results[0].address_components;

    const city = aC.find((el) => {
      return el.types.indexOf('locality') !== -1;
    });
    const state = aC.find((el) => {
      return el.types.indexOf('administrative_area_level_1') !== -1;
    });
    const country = aC.find((el) => {
      return el.types.indexOf('country') !== -1;
    });
    const postal = aC.find((el) => {
      return el.types.indexOf('postal_code') !== -1;
    });
    const street = aC.find((el) => {
      return el.types.indexOf('route') !== -1;
    });
    const streetNumber = aC.find((el) => {
      return el.types.indexOf('street_number') !== -1;
    });

    return {
      address: add,
      latitude: lat,
      longitude: lng,
      formatted_address: add,
      city: city ? city.long_name : '',
      state: state ? state.long_name : '',
      countryname: country ? country.long_name : '',
      country: country ? country.short_name : '',
      postalCode: postal ? postal.short_name : '',
      isFallback: !!isFallback,
      streetNumber: streetNumber?.long_name,
      street: street?.long_name,
    };
  }

  getUserLocation(askAccess = false): Observable<SummaryLocation> {
    this.initializeUserLocation(askAccess);

    return this.currentDeviceLocation.pipe(
      filter((data: SummaryCoordinates) => !!data),
      switchMap((data: SummaryCoordinates) => {
        if (
          this.lastGeocode?.latitude === data.latitude &&
          this.lastGeocode?.longitude === data.longitude
        ) {
          return of(this.lastGeocode);
        }

        if (isBot()) {
          return of({
            address: 'Bahnhofquai, 8001 Zürich, Switzerland',
            latitude: 47.3769,
            longitude: 8.5417,
            formatted_address: 'Bahnhofquai, 8001 Zürich, Switzerland',
            city: 'Zürich',
            state: 'Zürich',
            countryname: 'Switzerland',
            country: 'CH',
            postalCode: '8001',
            isFallback: false,
          });
        }

        const center = this.geocoding.LatLng(data.latitude, data.longitude);
        return this.geocoding.geocode(center).pipe(
          take(1),
          map((results: google.maps.GeocoderResult[]) => {
            this.lastGeocode = {
              ...this.getLocationFromGeocoderResult(results, data.isFallback),
              latitude: data.latitude,
              longitude: data.longitude,
            };
            return _.cloneDeep(this.lastGeocode);
          })
        );
      })
    );
  }
}
