export interface GridUser {
  id: number;
  displayName: string;
  slug: string;
  distance: number;
  currency: string;
  currencySymbol: string;
  isFavourite: boolean;
  new: boolean;
  vacation: boolean;
  verified: boolean;
  video: boolean;
  medias: UserMedia[];
  premium: boolean;
  role: number;
  establishmentSlug: string;
  establishmentRole: number;
  lowestPrice: number;
  serviceFeeIncluded: boolean;
}

export interface UserMedia {
  isAvatar: boolean;
  approved: UserMediaApprovedStatus;
  cover: boolean;
  media: string;
  thumb: string;
  thumbBlur: string;
  isImage: boolean;
  videoLoaded: boolean;
  blur: boolean;
  disabled?: boolean;
  id: number;
  notFound?: boolean;
}

export enum UserMediaApprovedStatus {
  PendingApproval,
  Approved,
  Rejected,
}
