import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Store } from '@ngrx/store';
import { setAuthTokenAction } from '@app/shared/actions/shared.actions';
import { CognitoRegisterModel } from '@app/shared/models/cognito-register.model';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@app/shared/services/config.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  refreshTokenTimer: any;

  constructor(
    private store: Store<any>,
    private configService: ConfigService,
    private http: HttpClient
  ) {}

  registerBasic(user: CognitoRegisterModel): Promise<any> {
    return parseInt(this.configService.config.emailableEnable, 10)
      ? this.http
          .get(
            `https://api.emailable.com/v1/verify?email=${encodeURIComponent(
              user.username
            )}&api_key=${this.configService.config.emailableKey}`
          )
          .pipe(
            map((data: any) => {
              if (data?.state !== 'deliverable') {
                throw new Error('Email is not valid');
              }

              return this.signUp(user);
            })
          )
          .toPromise()
      : this.signUp(user);
  }

  signUp(user): Promise<any> {
    return Auth.signUp({
      username: user.username,
      password: user.password,
      attributes: {
        'custom:location_latitude': (user.location?.latitude || '') + '',
        'custom:location_longitude': (user.location?.longitude || '') + '',
        'custom:role': user.role + '',
        'custom:language': user.language + '',
        'custom:countryCode': (user.countryCode || 'com') + '',
      },
      autoSignIn: {
        enabled: true,
      },
    });
  }

  loginBasic(username: string, password: string): Promise<any> {
    return Auth.signIn(username, password);
  }

  confirmRegister(username: string, code: string): Promise<any> {
    return Auth.confirmSignUp(username, code, { forceAliasCreation: false });
  }

  resendConfirmationCode(username: string): Promise<any> {
    return Auth.resendSignUp(username);
  }

  forgotPassword(username: string): Promise<any> {
    return Auth.forgotPassword(username);
  }

  completeNewPassword(userObj, password: string): Promise<any> {
    return Auth.completeNewPassword(userObj, password);
  }

  forgotPasswordSubmit(
    username: string,
    code: string,
    newPassword: string
  ): Promise<any> {
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  changePassword(user, oldPassword: string, newPassword: string): Promise<any> {
    return Auth.changePassword(user, oldPassword, newPassword);
  }

  checkUserSession() {
    Auth.currentUserInfo().then((currentUser) => {
      if (currentUser) {
        Auth.currentSession().then((session) => {
          const accessToken = session?.getAccessToken()?.getJwtToken() || null;
          this.store.dispatch(setAuthTokenAction({ accessToken: accessToken }));

          const exp = session?.getAccessToken()?.getExpiration();
          if (exp) {
            this.setRefreshToken(exp);
          }
        });
      } else {
        this.store.dispatch(setAuthTokenAction({ accessToken: null }));
      }
    });
  }

  setRefreshToken(expiration: number) {
    if (this.refreshTokenTimer) {
      clearTimeout(this.refreshTokenTimer);
    }
    const timeout = Math.max(
      1000,
      new Date(expiration * 1000).getTime() - new Date().getTime() - 1000 * 60
    );

    this.refreshTokenTimer = setTimeout(() => {
      try {
        Auth.currentUserInfo().then((userInfo) => {
          if (userInfo) {
            Auth.currentAuthenticatedUser().then((currentUser) => {
              Auth.currentSession().then((currentSession) => {
                currentUser.refreshSession(
                  currentSession.getRefreshToken(),
                  (err, session) => {
                    const { idToken, refreshToken, accessToken } = session;
                    this.store.dispatch(
                      setAuthTokenAction({
                        accessToken: accessToken.getJwtToken(),
                      })
                    );

                    const exp = accessToken?.getExpiration();
                    if (exp) {
                      this.setRefreshToken(exp);
                    }
                  }
                );
              });
            });
          } else {
            this.store.dispatch(setAuthTokenAction({ accessToken: null }));
          }
        });
      } catch (error) {
        this.store.dispatch(setAuthTokenAction({ accessToken: null }));
      }
    }, timeout);
  }
}
