import { createAction, props } from '@ngrx/store';
import { GingrPricingCommand } from '@app/account/containers/profile-v2/models/gingr-pricing.command';
import {
  UserAddressModel,
  UserAddressType,
} from '@app/shared/models/user-address.model';

export const setPricingCommand = createAction(
  '[Profile] Set Pricing command',
  props<{ pricingCommand: GingrPricingCommand }>()
);

export const setAddress = createAction(
  '[Profile] Set address',
  props<{ incallAddress: UserAddressModel }>()
);

export const setIncallAddressType = createAction(
  '[Profile] Set incall address type',
  props<{ incallAddressType: UserAddressType }>()
);

export const setHasIncallActivated = createAction(
  '[Profile] Set has incall activated',
  props<{ hasIncallActivated: boolean }>()
);

export const setHasOutcallActivated = createAction(
  '[Profile] Set has outcall activated',
  props<{ hasOutcallActivated: boolean }>()
);
