export interface ConversationRequest {
  instanceId: number;
  instanceType: ConversationRequestType;
  partnerName?: string;
  avatarUrl?: string;
}

export enum ConversationRequestType {
  INSTA_SESSION,
  BOOKING,
}
