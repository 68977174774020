import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getAuthToken } from '@app/shared/reducers/user.selectors';
import { filter, first, mergeMap } from 'rxjs/operators';
import { ConfigService } from '@app/shared/services/config.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<any>,
    private configService: ConfigService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      !request.url.startsWith(this.configService.config.apiV3) &&
      !request.url.startsWith(this.configService.config.wsUrl)
    ) {
      return next.handle(request);
    }

    return this.store.pipe(
      select(getAuthToken),
      filter((accessToken) => accessToken !== undefined),
      first(),
      mergeMap((token) => {
        const authReq = token
          ? request.clone({
              setHeaders: { Authorization: 'Bearer ' + token },
            })
          : request;
        return next.handle(authReq);
      })
    );
  }
}
