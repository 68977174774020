import { Injectable } from '@angular/core';
import { Filter } from '@app/instafeature/models/filter';
import { CommonService } from '@app/instafeature/services/common.service';
import { ServiceProvidersSearch } from '@app/instafeature/models/service-provider-search';
import { LocationModel } from '@app/instafeature/models/location';
import { select, Store } from '@ngrx/store';
import { saveServiceProviders } from '@app/instafeature/store/instafeature.actions';
import { isUserClient } from '@app/shared/reducers/user.selectors';
import { filter } from 'rxjs/operators';
import { getAppConfig } from '@app/shared/reducers/shared.selectors';
import { Config } from '@app/shared/models';
import { getInstaStateSelector } from '@app/instafeature/store/instafeature.selectors';
import { InstaState } from '@app/instafeature/models/insta-state';
import { ConnectType } from '@app/instafeature/models/connect-type';
import { SocketService } from '@app/instafeature/services/socket.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InstafeatureFilterService {
  filter = new Filter();
  isClient: boolean;
  public previousTabSelected: { tab: number; data: any } = {
    tab: 0,
    data: { zoom: 0, center: null },
  };
  config: Config;
  instaState: InstaState;
  filtersSelected = new BehaviorSubject<number>(null);
  numberedFilters = [
    'favourites',
    'new',
    'verified',
    'video',
    'gender',
    'sexuality',
    'priceLow',
    'ageLow',
    'services',
    'language',
    'hairColor',
    'ethnicity',
    'bodyType',
    'breastSize',
    'rating',
  ];
  listFilters = [
    'services',
    'language',
    'hairColor',
    'ethnicity',
    'bodyType',
    'gender',
    'sexuality',
    'breastSize',
  ];

  constructor(
    private commonService: CommonService,
    private store: Store,
    private socketService: SocketService
  ) {
    this.store
      .select(isUserClient)
      .pipe(filter((isClient) => isClient !== undefined))
      .subscribe((isClient) => {
        this.isClient = isClient;
      });

    this.store
      .pipe(
        select(getInstaStateSelector),
        filter((instaState: InstaState) => !!instaState)
      )
      .subscribe((instaState: InstaState) => (this.instaState = instaState));

    this.store
      .select(getAppConfig)
      .subscribe((config) => (this.config = config));
  }

  runSearch(location?: LocationModel) {
    if (location) {
      this.filter.longitude = location.longitude;
      this.filter.latitude = location.latitude;
    }
    this.updateSearch();
  }

  resetFilterAndUpdateSearch() {
    this.resetFilter();
    this.updateSearch();
  }

  resetFilter() {
    const longitude = this.filter.longitude;
    const latitude = this.filter.latitude;
    this.filter = new Filter();
    this.filter.latitude = latitude;
    this.filter.longitude = longitude;
  }

  updateSearch() {
    if (!this.isClient) {
      return;
    }

    this.updateFilter();
    this.commonService
      .filterGingr(this.filter)
      .subscribe((results: ServiceProvidersSearch[]) => {
        if (results?.length) {
          this.socketService.registerGingrWaitForUpdates(
            results.map((provider) => provider.id)
          );
        }

        this.store.dispatch(
          saveServiceProviders({ serviceProviders: results })
        );
      });
  }

  updateFilter() {
    this.filter.callType = this.instaState.callType;
    this.filter.outcallType = this.instaState.outcallType;
    this.filter.latitude =
      this.instaState.callType === ConnectType.OUTCALL &&
      this.instaState.address?.latitude
        ? this.instaState.address.latitude
        : this.filter.latitude;
    this.filter.longitude =
      this.instaState.callType === ConnectType.OUTCALL &&
      this.instaState.address?.longitude
        ? this.instaState.address.longitude
        : this.filter.longitude;

    this.countSelectedFilters();
  }

  countSelectedFilters() {
    let count = 0;
    this.numberedFilters.forEach((fltr) => {
      if (
        fltr === 'priceLow' &&
        (this.filter.priceLow > 0 || this.filter.priceHigh < 500)
      ) {
        count++;
      } else if (
        fltr === 'ageLow' &&
        (this.filter.ageLow > 18 || this.filter.ageHigh < 100)
      ) {
        count++;
      } else if (
        fltr === 'priceLow' ||
        fltr === 'priceHigh' ||
        fltr === 'ageLow' ||
        fltr === 'ageHigh'
      ) {
        return;
      } else if (
        this.filter[fltr]?.length > 0 &&
        this.listFilters.includes(fltr)
      ) {
        count += this.filter[fltr].length;
      } else {
        if (this.filter[fltr] === true || this.filter[fltr] > 0) {
          count++;
        }
      }
    });
    this.filtersSelected.next(count);
  }
}
