import { createAction, props } from '@ngrx/store';
import { TopUpMethodType } from '@app/wallet/models/top-up-method.type';
import { PayoutType } from '@app/wallet/models/payout-type';

export const clearTopUpAction = createAction('[Wallet] Clear Top Up');

export const setTopUpAmountAction = createAction(
  '[Wallet] Set Top Up amount',
  props<{ amount: number }>()
);

export const setTopUpPaymentMethodAction = createAction(
  '[Wallet] Set Top Up payment method',
  props<{ paymentMethod: TopUpMethodType }>()
);

export const getPendingBalance = createAction('[Wallet] Get pending balance');

export const setPendingBalanceAction = createAction(
  '[Wallet] Set user pending balance',
  props<{ pendingBalance: number }>()
);

export const setPayoutAmount = createAction(
  '[Wallet] Set payout amount',
  props<{ amount: number }>()
);

export const setPayoutType = createAction(
  '[Wallet] Set payout type',
  props<{ payoutType: PayoutType }>()
);

export const getBankInfoAction = createAction('[Credit] Get bank info');
