import { Injectable, OnDestroy } from '@angular/core';
import { NotificationService, UserService } from '@app/shared/services';
import { BehaviorSubject } from 'rxjs';
import { setUserAction } from '@app/shared/actions/shared.actions';
import { Store } from '@ngrx/store';
import { CurrentUser } from '@app/shared/models/current-user';
import {
  UserMedia,
  UserMediaApprovedStatus,
} from '@app/shared/models/grid/grid-user';
import { _t } from '@app/shared/helpers';
import { Router } from '@angular/router';
import { L10nTranslationService } from 'angular-l10n';

@Injectable({
  providedIn: 'root',
})
export class InformationFillingService implements OnDestroy {
  public INFORMATION_PARTS: any = {
    media: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: InformationFillingService.calculateMedia,
    },
    accountDetails: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: InformationFillingService.calculateGingrAccountDetails,
    },
    connectedGingrAccountDetails: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate:
        InformationFillingService.calculateConnectedGingrAccountDetails,
    },
    languagesOrigin: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: InformationFillingService.calculateLanguagesOrigin,
    },
    location: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: this.calculateLocation,
    },
    pricing: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: InformationFillingService.calculatePricing,
    },
    services: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: InformationFillingService.calculateServices,
    },
    availability: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: InformationFillingService.calculateAvailability,
    },
    defaultAvailability: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: this.calculateDefaultAvailability,
    },
    personalDetails: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: InformationFillingService.calculateGingrPersonalDetails,
    },
    facilities: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: InformationFillingService.calculateFacilities,
    },
    establishmentPricing: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: this.calculateTemp,
    },
    servicesOfGingr: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: this.calculateTemp,
    },
    establishmentAccountDetails: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate:
        InformationFillingService.calculateEstablishmentAccountDetails,
    },
    agencyAccountDetails: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: InformationFillingService.calculateAgencyAccountDetails,
    },
    extraDetails: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: InformationFillingService.calculateEstablishmentExtraDetails,
    },
    defaultSettings: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: this.calculateDefaultSettings.bind(this),
    },
    establishmentVerification: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: InformationFillingService.calculateEstablishmentVerification,
    },
    gingrVerification: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate: InformationFillingService.calculateGingrVerification,
    },
    connectedGingrVerification: {
      value$: new BehaviorSubject(0),
      value: 0,
      recalculate:
        InformationFillingService.calculateConnectedGingrVerification,
    },
  };

  public static getMandatoryFieldsFillPercentForClient(
    user: CurrentUser
  ): number {
    return Math.floor(
      (this.calculateClientAccountDetails(user) + (user?.mobile ? 100 : 0)) / 2
    );
  }

  public static getMandatoryFieldsFillPercentForGingr(
    user: CurrentUser,
    hasNotificationSet: boolean,
    isVerified: boolean
  ): number {
    return Math.floor(
      (this.calculateGingrAccountDetails(user) +
        (user?.mobile ? 100 : 0) +
        this.calculateLanguagesOrigin(user) +
        this.calculateMeetingPoint(user) +
        this.calculatePricing(user) +
        this.calculateMedia(user, false) +
        this.calculateServices(user) +
        this.calculateAvailability(user) +
        (hasNotificationSet ? 100 : 0) +
        (isVerified ? 100 : 0)) /
        10
    );
  }

  public static getOptionalFieldsFillPercentForClient(
    user: CurrentUser,
    hasNotificationSet: boolean,
    isVerified: boolean
  ): number {
    return Math.floor(
      (this.calculateClientPersonalDetails(user) +
        this.calculateMedia(user, true) +
        (hasNotificationSet ? 100 : 0) +
        (isVerified ? 100 : 0)) /
        4
    );
  }

  public static getOptionalFieldsFillPercentForGingr(
    user: CurrentUser
  ): number {
    return Math.floor(this.calculateGingrPersonalDetails(user));
  }

  private static calculateEstablishmentAccountDetails(user: any): number {
    if (!user) {
      return 0;
    }

    const countFields = 4;
    let total = 0;
    if (user.name) {
      total += 1;
    }

    if (user.displayName) {
      total += 1;
    }

    if (user.pricing?.incall?.currency) {
      total += 1;
    }

    if (user.mobile) {
      total += 1;
    }

    return Math.round((total / countFields) * 100);
  }

  private static calculateAgencyAccountDetails(user: any): number {
    if (!user) {
      return 0;
    }

    const countFields = 3;
    let total = 0;
    if (user.name) {
      total += 1;
    }
    if (user.displayName) {
      total += 1;
    }
    if (user.mobile) {
      total += 1;
    }

    return Math.round((total / countFields) * 100);
  }

  public static calculateLanguagesOrigin(user: CurrentUser): number {
    let total = 0;
    if (user?.nationality) {
      total += 50;
    }

    if (user?.languages?.length) {
      total += 50;
    }

    return total;
  }

  public static calculateServices(user: CurrentUser): number {
    if (!user?.services?.length) {
      return 0;
    }

    const mandatoryServices = user.services.filter((e) => !e.price).length;

    switch (mandatoryServices) {
      case 0:
        return 0;
      case 1:
        return 25;
      case 2:
        return 50;
      case 3:
        return 75;
      default:
        return 100;
    }
  }

  private static calculateFacilities(user: any): number {
    if (user?.services) {
      const services = user.services.filter((el) => {
        return el && el.name && el.name.indexOf('ESTABLISHMENTSERVICES') !== -1;
      });
      return services?.length > 0 ? 100 : 0;
    } else {
      return 0;
    }
  }

  private static calculateEstablishmentVerification(user: any): number {
    if (user) {
      const countFields = 6;
      let total = 0;
      if (user.emailverification) {
        total += 1;
      }
      if (user.smsverification) {
        total += 1;
      }
      if (user.phoneverification) {
        total += 1;
      }
      if (user.identify_photo && user.identify_photo.approved) {
        total += 1;
      }
      if (user.identify && user.identify.approved) {
        total += 1;
      }
      if (user.company_documents) {
        total += 1;
      }
      return Math.round((total / countFields) * 100);
    } else {
      return 0;
    }
  }

  private static calculateGingrVerification(user: any): number {
    if (user) {
      const countFields = 1;
      let total = 0;
      if (user.identify && user.identify.approved) {
        total += 1;
      }
      return Math.round((total / countFields) * 100);
    } else {
      return 0;
    }
  }

  private static calculateConnectedGingrVerification(user: any): number {
    if (user) {
      const countFields = 1;
      let total = 0;
      if (user.identify && user.identify.approved) {
        total += 1;
      }
      return Math.round((total / countFields) * 100);
    } else {
      return 0;
    }
  }

  private static calculateEstablishmentExtraDetails(user: any): number {
    if (user && user.profile) {
      const countFields = 5;
      let total = 0;
      if (user.profile.contact) {
        total += 1;
      }
      if (user.profile.notes) {
        total += 1;
      }
      if (user.profile.description) {
        total += 1;
      }
      if (user.profile.web) {
        total += 1;
      }
      if (user.profile.contactEmail) {
        total += 1;
      }
      return Math.round((total / countFields) * 100);
    } else {
      return 0;
    }
  }

  public static getGingrLastMandatoryUncompletedRoute(
    user: CurrentUser,
    currentStep: number
  ): string {
    if (
      currentStep > 1 &&
      InformationFillingService.calculateGingrAccountDetails(user) < 100
    ) {
      return '/account/profile/account';
    }

    if (currentStep > 2 && !user?.mobile) {
      return '/account/profile/phone';
    }

    if (
      currentStep > 3 &&
      InformationFillingService.calculateLanguagesOrigin(user) < 100
    ) {
      return '/account/profile/languages-origin';
    }

    if (
      currentStep > 4 &&
      InformationFillingService.calculateMeetingPoint(user) < 100
    ) {
      return '/account/profile/meeting-point';
    }

    if (
      currentStep > 5 &&
      InformationFillingService.calculatePricing(user) < 100
    ) {
      return '/account/profile/pricing';
    }

    if (
      currentStep > 6 &&
      InformationFillingService.calculateMedia(user, false) < 100
    ) {
      return '/account/profile/media';
    }

    if (
      currentStep > 7 &&
      InformationFillingService.calculateServices(user) < 100
    ) {
      return '/account/profile/services';
    }

    return null;
  }

  public static getClientLastMandatoryUncompletedRoute(
    user: CurrentUser,
    currentStep: number
  ): string {
    if (
      currentStep > 1 &&
      InformationFillingService.calculateClientAccountDetails(user) < 100
    ) {
      return '/account/profile/account';
    }
    return null;
  }

  public static calculateMedia(user: CurrentUser, isClient: boolean): number {
    if (!user.medias?.length) {
      return 0;
    }

    let total = 0;
    const hasAvatar = user.medias.some(
      (media) =>
        media.isAvatar && media.approved !== UserMediaApprovedStatus.Rejected
    );
    if (hasAvatar) {
      total += isClient ? 50 : 25;
    }

    const validMediaCount = user.medias.filter(
      (media: UserMedia) =>
        !media.isAvatar &&
        !media.cover &&
        media.isImage &&
        !media.disabled &&
        media.approved !== UserMediaApprovedStatus.Rejected
    ).length;
    return (
      total +
      (isClient
        ? validMediaCount
          ? 50
          : 0
        : 25 * (validMediaCount >= 3 ? 3 : validMediaCount))
    );
  }

  public static calculateMeetingPoint(user: CurrentUser) {
    return user.addresses?.length &&
      (!user.pricing?.outcall?.active ||
        user.pricing.outcall.home ||
        user.pricing.outcall.events ||
        user.pricing.outcall.hotel)
      ? 100
      : 0;
  }

  public static calculatePricing(user: CurrentUser): number {
    const incallActive = !!user?.pricing?.incall?.active;
    const hasIncallMandatorySet =
      incallActive && user.pricing.incall.hourlyRate;
    const outcallActive = !!user?.pricing?.outcall?.active;
    const hasOutcallMandatorySet =
      outcallActive && user.pricing.outcall.hourlyRate;

    let total = 0;
    let countFields = 0;
    if (incallActive) {
      total++;
      countFields += hasIncallMandatorySet ? 1 : 0;
    }
    if (outcallActive) {
      total++;
      countFields += hasOutcallMandatorySet ? 1 : 0;
    }

    return countFields ? Math.round((total / countFields) * 100) : 0;
  }

  private static calculateConnectedGingrAccountDetails(user: any): number {
    const countFields = 4;
    let total = 0;

    if (user === undefined || user === null) {
      return 0;
    }
    if (user.displayName) {
      total += 1;
    }
    if (user.sex != null && user.sex !== '') {
      total += 1;
    }
    if (user.sexuality != null && user.sexuality !== '') {
      total += 1;
    }
    if (user?.profile?.year_of_birth || user.profile?.dob) {
      total += 1;
    }

    return Math.round((total / countFields) * 100);
  }

  public static calculateGingrAccountDetails(user: CurrentUser): number {
    const countFields = 7;
    let total = 0;

    if (!user) {
      return 0;
    }

    if (user?.displayName) {
      total += 1;
    }
    if (user?.birthYear) {
      total += 1;
    }
    if (Number.isFinite(user.gender)) {
      total += 1;
    }
    if (Number.isFinite(user.sexuality)) {
      total += 1;
    }
    if (user?.email) {
      total += 1;
    }
    if (user?.bookingNotes) {
      total += 1;
    }
    if (user?.aboutMe) {
      total += 1;
    }

    return Math.round((total / countFields) * 100);
  }

  public static calculateAvailability(user: any): number {
    if (
      user &&
      user.availability !== undefined &&
      user.availability.length > 0
    ) {
      let hasTimeSet = false;
      for (let d = 0; d < user.availability.length; d++) {
        if (user.availability[d].from || user.availability[d].fullDay) {
          hasTimeSet = true;
        }
      }

      if (hasTimeSet) {
        return 100;
      }
    }
    return 0;
  }

  public static calculateClientAccountDetails(user: any): number {
    const countFields = 3;
    let total = 0;

    if (!user) {
      return 0;
    }

    if (user.displayName) {
      total += 1;
    }
    if (Number.isFinite(user.gender)) {
      total += 1;
    }
    if (Number.isFinite(user.sexuality)) {
      total += 1;
    }

    return Math.round((total / countFields) * 100);
  }

  public static calculateGingrPersonalDetails(user: CurrentUser): number {
    const countFields = 19;
    let total = 0;

    if (!user) {
      return 0;
    }

    if (user.occupation) {
      total += 1;
    }
    if (user.preferences.drinks) {
      total += 1;
    }
    if (user.preferences.smoke !== null) {
      total += 1;
    }
    if (user.preferences.drinks) {
      total += 1;
    }
    if (user.preferences.alcohol !== null) {
      total += 1;
    }
    if (user.preferences.interests) {
      total += 1;
    }
    if (user.preferences.food) {
      total += 1;
    }
    if (user.physicalDetails.chest) {
      total += 1;
    }
    if (user.physicalDetails.waist) {
      total += 1;
    }
    if (user.physicalDetails.stomach) {
      total += 1;
    }
    if (user.physicalDetails.height) {
      total += 1;
    }
    if (user.physicalDetails.weight) {
      total += 1;
    }
    if (user.physicalDetails.hairColor) {
      total += 1;
    }
    if (user.physicalDetails.eyeColor) {
      total += 1;
    }
    if (user.physicalDetails.genitaliaArea) {
      total += 1;
    }
    if (user.physicalDetails.cupSize) {
      total += 1;
    }
    if (user.physicalDetails.breastImplant !== null) {
      total += 1;
    }
    if (
      user.physicalDetails.piercingFace !== null ||
      user.physicalDetails.piercingIntimate !== null ||
      user.physicalDetails.tattoo !== null
    ) {
      total += 1;
    }
    if (user.bodyTypes?.length) {
      total += 1;
    }

    return Math.round((total / countFields) * 100);
  }

  public static calculateClientPersonalDetails(user: CurrentUser): number {
    if (!user) {
      return;
    }
    const countFields = 6;
    let total = 0;
    if (user.physicalDetails.height) {
      total += 1;
    }
    if (user.physicalDetails.weight) {
      total += 1;
    }
    if (user.bookingNotes) {
      total += 1;
    }
    if (user.birthYear) {
      total += 1;
    }
    if (user.physicalDetails.genitaliaArea) {
      total += 1;
    }
    if (user.preferences.hearAboutUs) {
      total += 1;
    }

    return Math.round((total / countFields) * 100);
  }

  constructor(
    private notify: NotificationService,
    private userService: UserService,
    private router: Router,
    private translate: L10nTranslationService,
    private store: Store
  ) {
    for (const prop in this.INFORMATION_PARTS) {
      if (this.INFORMATION_PARTS.hasOwnProperty(prop)) {
        this.INFORMATION_PARTS[prop].value =
          this.INFORMATION_PARTS[prop].value$.asObservable();
      }
    }
  }

  ngOnDestroy() {}

  public checkUncompletedTiles(
    isUserClient: boolean,
    user,
    currentStep: number
  ) {
    const mandatoryUncompletedRoute = isUserClient
      ? InformationFillingService.getClientLastMandatoryUncompletedRoute(
          user,
          currentStep
        )
      : InformationFillingService.getGingrLastMandatoryUncompletedRoute(
          user,
          currentStep
        );
    if (mandatoryUncompletedRoute) {
      this.notify.info(
        null,
        this.translate.translate(_t('ACCOUNT.uncompleted tiles'))
      );
      this.router.navigate([mandatoryUncompletedRoute]);
    }
  }

  calculateDefaultAvailability(user: any): number {
    return user?.availabilityDefault?.length > 0 ? 100 : 0;
  }

  calculateLocation(user: any): number {
    return user?.location?.latitude && user.location.longitude ? 100 : 0;
  }

  private calculateDefaultSettings(user: any): number {
    let value: number = 0;
    value += this.calculateDefaultAvailability(user);
    value += InformationFillingService.calculatePricing(user);
    value += InformationFillingService.calculateServices(user);
    return Math.ceil(value / 3);
  }

  calculateTemp() {
    return 100;
  }
}
