import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CreditService } from '@app/shared/services/credit.service';

import {
  getPendingBalance,
  setPendingBalanceAction,
} from '@app/wallet/store/wallet.actions';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WalletEffects {
  private readonly creditService = inject(CreditService);
  private readonly actions$ = inject(Actions);

  getUserPendingBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPendingBalance),
      switchMap(() => this.creditService.getPending()),
      map((result) => setPendingBalanceAction({ pendingBalance: result }))
    )
  );
}
