<div
  fxLayout="row"
  class="location-selector-container w-full py-10 px-0 xl:pt-[56px]"
>
  <div
    class="w-full border-2 border-primary rounded px-5 text-white py-2 flex items-center gap-4"
  >
    <input
      (blur)="unfocus()"
      [(ngModel)]="locationTextLabel"
      type="text"
      class="form-control location-text focus:outline-none text-white bg-transparent placeholder-white"
      #search
    />
    <img
      alt="close"
      src="assets/icons/cross.svg"
      class="cursor-pointer"
      (click)="deleteLocation()"
    />
    <svg-icon
      *ngIf="extraIcon"
      src="/assets/svg-icons/common/map-starting-point.svg"
      [svgStyle]="{
        'width.px': 24,
        'height.px': 24
      }"
    ></svg-icon>
  </div>
</div>
