import { TopUpMethodType } from '@app/wallet/models/top-up-method.type';
import { createReducer, on } from '@ngrx/store';
import {
  clearTopUpAction,
  setPayoutAmount,
  setPayoutType,
  setPendingBalanceAction,
  setTopUpAmountAction,
  setTopUpPaymentMethodAction,
} from '@app/wallet/store/wallet.actions';
import { PayoutType } from '@app/wallet/models/payout-type';

export interface WalletState {
  amount?: number;
  paymentMethod?: TopUpMethodType;
  pendingBalance: number;
  payoutAmount: number;
  payoutType: PayoutType;
}

export const initialCreditState: WalletState = {
  amount: undefined,
  paymentMethod: undefined,
  pendingBalance: 0,
  payoutAmount: 0,
  payoutType: undefined,
};

export const walletReducer = createReducer(
  initialCreditState,
  on(clearTopUpAction, () => initialCreditState),
  on(setTopUpPaymentMethodAction, (state, { paymentMethod }) => ({
    ...state,
    paymentMethod,
  })),
  on(setTopUpAmountAction, (state, { amount }) => ({
    ...state,
    amount,
  })),
  on(setPendingBalanceAction, (state, { pendingBalance }) => ({
    ...state,
    pendingBalance,
  })),
  on(setPayoutType, (state, { payoutType }) => ({
    ...state,
    payoutType,
  })),
  on(setPayoutAmount, (state, { amount }) => ({
    ...state,
    payoutAmount: amount,
  }))
);
