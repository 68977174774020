export class Filter {
  displayname: string;
  ageRange: number[];
  bodytype: number[];
  haircolor: number[];
  distanceRange: number[];
  hair: number[];
  origen: number[];
  speaks: number[];
  sexuality: number[];
  travels: number[];
  sex: number[];
  services: number[];
  justFavourites: boolean;
  online: boolean;
  inactive: boolean;
  incall: boolean;
  outcall: boolean;
  new: boolean;
  verified: boolean;
  video: boolean;
  open: boolean;
  gingrs: boolean;
  price: number[];
  priceRange: number[];
  role: number[];
  distance: number[];
  establishmentservice: number[];
}
