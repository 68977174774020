import crawlers from 'assets/helpers/crawler-user-agents.json';

export function isBot(): boolean {
  const searchUrlParams = new URLSearchParams(window.location.search);
  const isCrawlerParam = searchUrlParams.get('isCrawlerParam');
  if (isCrawlerParam) {
    return true;
  }

  const botPattern = crawlers.map((e) => e.pattern).join('|');
  const re = new RegExp(botPattern, 'i');
  const userAgent = navigator.userAgent;
  return re.test(userAgent);
}
