import { createReducer, on } from '@ngrx/store';
import { GingrPricingCommand } from '@app/account/containers/profile-v2/models/gingr-pricing.command';
import {
  setAddress,
  setHasIncallActivated,
  setHasOutcallActivated,
  setIncallAddressType,
  setPricingCommand,
} from '@app/account/containers/profile-v2/store/profile-v2.actions';
import {
  UserAddressModel,
  UserAddressType,
} from '@app/shared/models/user-address.model';

export interface ProfileV2State {
  pricingCommand: GingrPricingCommand;
  incallAddress: UserAddressModel;
  incallAddressType: UserAddressType;
  hasOutcallActivated: boolean;
  hasIncallActivated: boolean;
}

export const initialProfileV2: ProfileV2State = {
  pricingCommand: undefined,
  incallAddress: undefined,
  incallAddressType: undefined,
  hasOutcallActivated: undefined,
  hasIncallActivated: undefined,
};

export const profileV2Reducer = createReducer(
  initialProfileV2,
  on(setPricingCommand, (state, { pricingCommand }) => ({
    ...state,
    pricingCommand,
  })),
  on(setAddress, (state, { incallAddress }) => ({
    ...state,
    incallAddress,
  })),
  on(setIncallAddressType, (state, { incallAddressType }) => ({
    ...state,
    incallAddressType,
  })),
  on(setHasOutcallActivated, (state, { hasOutcallActivated }) => ({
    ...state,
    hasOutcallActivated,
  })),
  on(setHasIncallActivated, (state, { hasIncallActivated }) => ({
    ...state,
    hasIncallActivated,
  }))
);
