import { Injectable } from '@angular/core';
import { Filter } from '../models/filter';
import { Subject, Observable, ReplaySubject } from 'rxjs';
import * as _ from 'lodash-es';
import { setGridProfilesFiltersAction } from '@app/shared/actions/shared.actions';
import { GetLoc, SummaryLocation } from '@app/shared/models';
import { select, Store } from '@ngrx/store';
import { CookiebotModel } from '@app/shared/models/cookiebot.model';
import { StorageService } from '@app/shared/services/storage.service';
import { getUserLocation } from '@app/shared/reducers/shared.selectors';
import { filter } from 'rxjs/operators';
import { StaticUtilsService } from '@app/shared/services/static-utils.service';
import { FeatureFlags } from '@app/shared/models/constants';

declare var Cookiebot: CookiebotModel;

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private openFilterSetter = new Subject<boolean>();
  private resetSidenavFilter$: ReplaySubject<boolean> = new ReplaySubject();
  private resetAllFilter = new Subject<any>();

  public openFilterSetter$ = this.openFilterSetter.asObservable();
  public isNeedResetSidenavFilter: Observable<boolean> =
    this.resetSidenavFilter$.asObservable();
  public resetAllFilter$ = this.resetAllFilter.asObservable();

  routeFilters: any = [
    {
      route: /gingrs/,
      role: [1, 2],
      name: 'Gingrs',
    },
    {
      route: /establishments/,
      role: [5, 6],
      name: 'Establishments',
    },
    {
      route: /agencies/,
      role: [8, 9],
      name: 'Agencies',
    },
  ];

  private defaultGingrFilter = {
    role: [1, 2],
    sex: [0],
    sexuality: null,
  };

  private defaultEstablishmentsFilter = {
    role: [5, 6],
  };

  private defaultAgenciesFilter = {
    role: [8, 9],
  };

  userLatitude;
  userLongitude;

  constructor(private store: Store<any>, private storage: StorageService) {
    this.store
      .pipe(
        select(getUserLocation),
        filter((location: SummaryLocation) => !!location)
      )
      .subscribe((loc) => {
        this.userLatitude = loc.latitude;
        this.userLongitude = loc.longitude;
      });
  }

  checkWhichRoleNeedsToBeLoaded(
    currentRouteUrl: string,
    getLoc: GetLoc
  ): string {
    const tile = _.find(this.routeFilters, (el) => {
      return el.route.test(currentRouteUrl);
    });

    if (!tile) {
      return null;
    }
    const currentRole = tile.name;

    // If name is Gingr, check if any filters have been applied
    // If not set a new filter with Female gender selected
    const hasGingrFilter = this.storage.get('filter-settings');
    const hasAgenciesFilter = this.storage.get('agencies-filter');
    const hasEstablishmentsFilter = this.storage.get('establishments-filter');

    const hasCookieBanner = StaticUtilsService.LdClient.variation(
      FeatureFlags.CookieBar,
      false
    ) as boolean;

    if (currentRole === 'Gingrs') {
      if (!hasGingrFilter) {
        if (!hasCookieBanner || Cookiebot?.consent?.preferences) {
          this.storage.set('filter-settings', this.defaultGingrFilter);
        }
        this.setFilter(getLoc, tile.role, [0], null);
      } else {
        this.setFilter(getLoc, tile.role, null, hasGingrFilter);
      }
    }

    if (currentRole === 'Agencies') {
      if (!hasAgenciesFilter) {
        if (!hasCookieBanner || Cookiebot?.consent?.preferences) {
          this.storage.set('agencies-filter', this.defaultAgenciesFilter);
        }
        this.setFilter(getLoc, tile.role, null, null);
      } else {
        this.setFilter(getLoc, tile.role, null, hasAgenciesFilter);
      }
    }

    if (currentRole === 'Establishments') {
      if (!hasEstablishmentsFilter) {
        if (!hasCookieBanner || Cookiebot?.consent?.preferences) {
          this.storage.set(
            'establishments-filter',
            this.defaultEstablishmentsFilter
          );
        }
        this.setFilter(getLoc, tile.role, null, null);
      } else {
        this.setFilter(getLoc, tile.role, null, hasEstablishmentsFilter);
      }
    }

    this.resetSidenavFilter();
    return currentRole;
  }

  setFilter(getLoc: GetLoc, role: number[], sex: number[], filterFromStorage) {
    if (!getLoc) {
      return;
    }

    if (filterFromStorage) {
      getLoc.filter = filterFromStorage;
    } else {
      getLoc.filter = this.getSimpleFilter(role, sex, null);
    }
    getLoc.latitude = this.userLatitude;
    getLoc.longitude = this.userLongitude;

    this.store.dispatch(
      setGridProfilesFiltersAction({ data: _.cloneDeep(getLoc) })
    );
  }

  resetAllFilters() {
    this.resetAllFilter.next(true);
  }

  resetSidenavFilter() {
    this.resetSidenavFilter$.next(true);
  }

  getSimpleFilter(role: number[], sex: number[], sexuality: number[]): Filter {
    const filter = new Filter();

    if (role) {
      filter.role = role;
    }
    if (sexuality) {
      filter.sexuality = sexuality;
    }
    if (sex) {
      filter.sex = sex;
    }

    return filter;
  }

  checkOpenedFilter(isOpen: boolean) {
    this.openFilterSetter.next(isOpen);
  }
}
