import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigModel } from '@app/shared/models/config.model';
import { APP_CONFIG, FeatureFlags } from '@app/shared/models/constants';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataSpikeApplicantModel } from '@app/shared/models/data-spike/data-spike-applicant.model';
import {
  DataSpikeVerificationModel,
  DataSpikeVerificationStatus,
} from '@app/shared/models/data-spike/data-spike-verification.model';
import { StaticUtilsService } from '@app/shared/services/static-utils.service';
import { KycVerificationType } from '@app/shared/models/data-spike/kyc-verification.type';

@Injectable({
  providedIn: 'root',
})
export class DataspikeService {
  private readonly http = inject(HttpClient);
  private readonly config: ConfigModel = inject(APP_CONFIG);

  getApplicant(): Observable<DataSpikeApplicantModel> {
    const skipVerification = StaticUtilsService.LdClient.variation(
      FeatureFlags.SkipKycVerification,
      false
    );
    if (skipVerification) {
      return of({
        providedInfo: { full_name: 'Dummy name (flag)' },
      } as DataSpikeApplicantModel);
    }
    return this.http
      .get<DataSpikeApplicantModel>(`${this.config.apiV3}/dataspike/applicant`)
      .pipe(catchError(() => of(null)));
  }

  createApplicant(
    fullName: string,
    gender: string,
    birthday: string
  ): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.config.apiV3}/dataspike/applicant`, {
        fullName,
        gender,
        birthday,
      })
      .pipe(catchError(() => of(null)));
  }

  updateApplicant(
    fullName: string,
    gender: string,
    birthday: string
  ): Observable<boolean> {
    return this.http
      .patch<boolean>(`${this.config.apiV3}/dataspike/applicant`, {
        fullName,
        gender,
        birthday,
      })
      .pipe(catchError(() => of(null)));
  }

  createVerification(
    verificationType: KycVerificationType
  ): Observable<DataSpikeVerificationModel> {
    return this.http
      .post<DataSpikeVerificationModel>(
        `${this.config.apiV3}/dataspike/verification?verificationType=${verificationType}`,
        {}
      )
      .pipe(catchError(() => of(null)));
  }

  getVerification(
    verificationType: KycVerificationType
  ): Observable<DataSpikeVerificationModel> {
    const skipVerification = StaticUtilsService.LdClient.variation(
      FeatureFlags.SkipKycVerification,
      false
    );
    if (skipVerification) {
      return of({
        status: DataSpikeVerificationStatus.Verified,
        createdAt: '',
        completedAt: '',
        verificationUrlId: '',
        expiresAt: '',
      });
    }
    return this.http
      .get<DataSpikeVerificationModel>(
        `${this.config.apiV3}/dataspike/verification?verificationType=${verificationType}`
      )
      .pipe(catchError(() => of(null)));
  }
}
