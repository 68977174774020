import { ConnectType } from '@app/instafeature/models/connect-type';
import { OutcallType } from '@app/instafeature/models/outcall-type';

export class Filter {
  callType: ConnectType = null;
  verified = false;
  favourites = false;
  outcallType: OutcallType = null;
  new = false;
  video = false;
  gender: number[] = [];
  sexuality: number[] = [];
  hairColor: number[] = [];
  ethnicity: number[] = [];
  bodyType: number[] = [];
  priceLow = 0;
  priceHigh = 1000;
  ageLow = 18;
  ageHigh = 100;
  breastSize: string[] = [];
  rating?: number;
  language: number[] = [];
  services: number[] = [];
  longitude: number = null;
  latitude: number = null;
  search: string = null;
}
