import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Attribute } from '@angular/compiler';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgIf } from '@angular/common';

/*
export type BtnSize = 'small' | 'large';
export type BtnType = 'normal' | 'primary' | 'secondary';
export type Disabled = true | false;
*/

@Component({
    selector: 'gingr-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    standalone: true,
    imports: [NgClass, ExtendedModule, NgIf]
})
export class ButtonComponent implements OnInit {
  @Input() type: string = 'normal';
  @Input() size: string = 'large';
  @Input() subText: string;
  @Input() dataPercent: string;
  @Input() btnDisabled: boolean = false;
  @Output() update = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {

  }

  onBtnClicked(event) {
      this.update.emit(event);
  }
}
