<button
  class="gg-button"
  [ngClass]="[
    btnDisabled ? 'disabled' : '',
    type === 'secondary'
      ? 'gg-button--secondary'
      : type === 'danger'
      ? 'gg-button--danger'
      : 'gg-button--primary',
    size !== 'large' ? 'gg-button--' + size : ''
  ]"
  [disabled]="btnDisabled"
  (click)="onBtnClicked($event)"
  [attr.dataPercent]="dataPercent"
>
  <span>
    <ng-content></ng-content>
  </span>
  <span
    class="subText"
    [ngClass]="{ disabled: btnDisabled }"
    *ngIf="subText && !btnDisabled"
  >
    {{ subText }}
  </span>
  <span
    class="subText disabled"
    *ngIf="subText && btnDisabled"
    [innerHTML]="btnDisabled ? subText : ''"
  >
  </span>
</button>
