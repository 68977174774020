import { createAction, props } from '@ngrx/store';
import { BookWhenType } from '@app/book/models/book-when-type';
import { ConnectType } from '@app/instafeature/models/connect-type';
import { BookingProviderModel } from '@app/shared/models/booking/book-provider.model';
import { BookingUserAddress } from '@app/shared/models/booking/booking-details.model';
import { OutcallType } from '@app/instafeature/models/outcall-type';
import { OutcallTypeBook } from '@app/book/models/outcall-type';

export const setBookStepAction = createAction(
  '[Book] Set Book step',
  props<{ step: number }>()
);

export const setBookResetAction = createAction('[Book] Set Book reset');

export const setBookProfileAction = createAction(
  '[Book] Set Book profile',
  props<{ profile: BookingProviderModel }>()
);

export const setBookTimeDurationAction = createAction(
  '[Book] Set Book time duration',
  props<{ startDate: Date; duration: number }>()
);

export const setBookWhenAction = createAction(
  '[Book] Set Book when',
  props<{ when: BookWhenType; profileId: number }>()
);

export const setBookOutcallTypeAction = createAction(
  '[Book] Set Book outcall type',
  props<{ outcallType: OutcallType }>()
);

export const setBookPaymentMethodAction = createAction(
  '[Book] Set Book payment method',
  props<{ paymentMethod: boolean }>()
);

export const setBookDetailsReviewedAction = createAction(
  '[Book] Set Book details reviewed',
  props<{ detailsReviewed: boolean }>()
);

export const setBookMessageAction = createAction(
  '[Book] Set Book message',
  props<{ message: string }>()
);

export const setBookPlaceTypeAction = createAction(
  '[Book] Set Book place type',
  props<{ placeType: ConnectType }>()
);

export const setBookServices = createAction(
  '[Book] Set Book services',
  props<{ services: number[] }>()
);

export const setBookTravelDistanceAction = createAction(
  '[Book] Set Book travel distance',
  props<{ travelDistance: number; distanceId: string }>()
);

export const clearBookTravelDistanceAction = createAction(
  '[Book] Clear Book travel distance'
);

export const setBookAddress = createAction(
  '[Book] Set Book address',
  props<{ address: BookingUserAddress }>()
);

export const setCallTypeAvailable = createAction(
  '[Book] Set call type available',
  props<{ callTypeAvailable: ConnectType }>()
);

export const setOutcallTypeAvailable = createAction(
  '[Book] Set Book outcall type available',
  props<{ outcallTypeAvailable: OutcallTypeBook }>()
);

export const setCashAvailable = createAction(
  '[Book] Set cash available',
  props<{ cashAvailable: boolean }>()
);
