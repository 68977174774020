import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  CacheAgencyProfiles,
  CacheAgencyProfilesScroll,
  CacheEstablishmentProfiles,
  CacheEstablishmentProfilesScroll,
  CacheGingrProfiles,
  CacheGingrProfilesScroll,
} from '@app/shared/models/constants';
import { select, Store } from '@ngrx/store';
import {
  getAppConfig,
  getServiceFeeAppointment,
} from '@app/shared/reducers/shared.selectors';
import { UserRoles } from '@app/shared/models/enum/userroles';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@app/shared/services/config.service';
import { GridFilter } from '@app/shared/models/gridFilter';
import { GridUser } from '@app/shared/models/grid/grid-user';

@Injectable({
  providedIn: 'root',
})
export class ProfilesService {
  // Observable variable
  profiles = new BehaviorSubject<GridUser[]>(null);
  totalProfilesCount = new BehaviorSubject<number>(null);
  readyNowProfilesCount = new BehaviorSubject<number>(null);
  currentProfiles$ = this.profiles.asObservable();
  registerPlate = new ReplaySubject<Object>();
  currentRegisterPlate = this.registerPlate.asObservable();
  // no profiles founded
  private noProfiles$ = new BehaviorSubject<boolean>(false);
  public noProfiles = this.noProfiles$.asObservable();

  // no profiles founded
  private isLoading$ = new BehaviorSubject<boolean>(true);
  public isLoading = this.isLoading$.asObservable();
  private currentFilter: GridFilter;
  profilesCount = new BehaviorSubject<number>(0);
  readonly appConfig = this.store.selectSignal(getAppConfig);
  readonly escortFee = this.store.selectSignal(getServiceFeeAppointment);

  constructor(
    private store: Store<any>,
    public http: HttpClient,
    public configService: ConfigService
  ) {}

  getProfilesCacheKey(currentRole: string): string {
    switch (currentRole?.toLowerCase()) {
      case 'gingr':
      case 'gingrs':
        return CacheGingrProfiles;
      case 'establishment':
      case 'establishments':
      case 'brothels':
        return CacheEstablishmentProfiles;
      case 'agency':
      case 'agencies':
        return CacheAgencyProfiles;
    }

    return null;
  }

  getProfilesCacheKeyByRole(currentRole: UserRoles): string {
    switch (currentRole) {
      case UserRoles.SERVICE_PROVIDER_BASIC:
        return CacheGingrProfiles;
      case UserRoles.ESTABLISHMENT_BASIC:
        return CacheEstablishmentProfiles;
      case UserRoles.AGENCY_BASIC:
        return CacheAgencyProfiles;
    }

    return null;
  }

  getProfilesScrollCacheKey(currentRole: string): string {
    switch (currentRole?.toLowerCase()) {
      case 'gingr':
      case 'gingrs':
        return CacheGingrProfilesScroll;
      case 'establishment':
      case 'establishments':
      case 'brothels':
        return CacheEstablishmentProfilesScroll;
      case 'agency':
      case 'agencies':
        return CacheAgencyProfilesScroll;
    }

    return null;
  }

  getProfiles(
    gridFilter?: GridFilter,
    pagination?: { limit: number; offset: number },
    countOnly = false
  ): Observable<GridUser[]> {
    if (countOnly) {
      this.getTotalProfilesCount(gridFilter, true);
      return of([]);
    }

    if (!gridFilter) {
      gridFilter = this.currentFilter;
    }
    if (pagination) {
      gridFilter.pagination = pagination;
    }
    this.currentFilter = gridFilter;

    if (!gridFilter.pagination?.offset && !countOnly) {
      this.profiles.next(undefined);
      this.getTotalProfilesCount({ ...gridFilter, readyNow: true });
    }

    const url =
      gridFilter.userRoles.includes(UserRoles.SERVICE_PROVIDER_BASIC) ||
      gridFilter.userRoles.includes(UserRoles.SERVICE_PROVIDER_PRO)
        ? 'gingrs'
        : 'establishments';

    return this.http
      .post<
        GridUser[]
      >(`${this.configService.config.apiV3}/grid/${url}`, gridFilter)
      .pipe(
        map((profiles) => {
          profiles.forEach((p) => {
            p.lowestPrice = this.valueWithTax(p);
          });
          this.profiles.next(profiles);
          this.profilesCount.next(profiles.length);
          return profiles;
        }),
        catchError(() => of([]))
      );
  }

  valueWithTax(profile: GridUser): number {
    if (profile?.serviceFeeIncluded) {
      return profile.lowestPrice;
    }

    return (
      profile.lowestPrice +
      Math.round((profile.lowestPrice * this.escortFee()) / 100)
    );
  }

  getTotalProfilesCount(gridFilter: GridFilter, totalCount = false) {
    this.http
      .post<number>(
        `${this.configService.config.apiV3}/grid/gingrs/count`,
        gridFilter
      )
      .subscribe((data) => {
        if (totalCount) {
          this.totalProfilesCount.next(data);
        } else {
          this.readyNowProfilesCount.next(data);
        }
      });
  }

  pushProfiles(profiles: Array<any>): void {
    this.profiles.next(profiles);
  }
}
