import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StorageService {
  ls = localStorage;

  constructor() {} // private ls = localStorage

  set(name: string, value: any) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    this.ls.setItem(name, value);
  }

  get(name: string): any {
    let value = this.ls.getItem(name);
    if (!value) {
      return false;
    }

    if (value === 'undefined') {
      return false;
    }

    try {
      if (JSON.parse(value)) {
        value = JSON.parse(value);
      }
    } catch (e) {
      // could not parse as object
    }

    return value;
  }

  delete(name: string) {
    this.ls.removeItem(name);
  }

  isOlder(key: string, days: number) {
    const val = this.get(key);

    if (!val) {
      return true;
    }

    if (val.timestamp) {
      const td = days * 8640;
      const now = Math.floor(Date.now() / 1000);

      const diff = now - val.timestamp;

      return diff > td;
    } else {
      return false;
    }
  }

  clear() {
    this.ls.clear();
  }

  unshift(name: string, value: any) {
    let arr = this.get(name);
    if (!(arr instanceof Array)) {
      arr = [];
    }

    // remove when user visit the same user
    if (name === 'lastSeen') {
      arr = arr.filter((element: any) => {
        return element.id !== value.id;
      });
    }

    if (arr.length > 6) {
      while (arr.length > 8) {
        arr.pop();
      }
      arr.unshift(value);
    } else {
      arr.push({});
      for (let i = arr.length - 1; i > 0; i--) {
        arr[i] = arr[i - 1];
      }

      arr[0] = value;
    }

    this.set(name, arr);
  }
}
