export interface NotificationSettingModel {
  enabled: boolean;
  uninitialized: boolean;
  notificationType: NotificationSettingType;
}

export enum NotificationSettingType {
  WhatsApp = 'WhatsApp',
  Telegram = 'Telegram',
  Push = 'Push',
}
