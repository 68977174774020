import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { _t } from '@app/shared/helpers';
import { InformationFillingService } from '@app/account/services/information-filling.service';
import { EventEmitter as Events } from 'events';
import {
  EstablishmentConnection,
  EstablishmentConnectionStatus,
  User,
  UserAvailableRoles,
} from '@app/shared/models';
import { UserService } from '@app/shared/services';
import { L10nTranslationService } from 'angular-l10n';
import { UserGroups } from '@app/shared/models/enum/user-groups';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AccountMenuService implements OnDestroy {
  private $router: Subscription;
  // to emit save action from gingr-account-save-next-bar
  private $actionEmitter: ReplaySubject<string>;
  private actionEmitter: Observable<string>;
  // event to know that data be saved emit('saved')
  public userDataEvents: Events = new Events();
  // variable to know sidenav account status
  private sidenavAccountState$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public sidenavAccountState: Observable<boolean> =
    this.sidenavAccountState$.asObservable();

  private mandatoryStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  // account header text value
  private accountHeader$: BehaviorSubject<string> = new BehaviorSubject(null);
  public accountHeader: Observable<string> = this.accountHeader$.asObservable();
  public saveNextMenuRef: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private filling: InformationFillingService,
    public translation: L10nTranslationService,
    @Inject(MatBottomSheet) private saveNextMenu: MatBottomSheet
  ) {
    this.$router = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.saveNextMenuRef) {
          this.saveNextMenuRef.dismiss();
        }

        // this.saveNextMenuRef = null;
        this.subscriptions.forEach((subscription: Subscription) => {
          if (subscription) {
            subscription.unsubscribe();
          }
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.$router instanceof Subscription) {
      this.$router.unsubscribe();
    }
  }

  public updateSidenavState(state: boolean) {
    this.sidenavAccountState$.next(state);
  }

  getMandatoryMenu(
    profileType: UserGroups,
    managedUser?: boolean,
    establishmentConnection?: EstablishmentConnection
  ): Array<any> {
    const returnArray = [];
    let ordering = 1;

    if (
      !(
        profileType === UserGroups.GINGR &&
        managedUser &&
        establishmentConnection?.status === EstablishmentConnectionStatus.FREE
      )
    ) {
      returnArray.push({
        service_name: 'account_details',
        name: this.translation.translate(_t('ACCOUNT.Account Details')),
        fill_percent:
          profileType === UserGroups.GINGR
            ? establishmentConnection?.isManaged
              ? this.filling.INFORMATION_PARTS.connectedGingrAccountDetails
                  .value
              : this.filling.INFORMATION_PARTS.accountDetails.value
            : profileType === UserGroups.ESTABLISHMENT
              ? this.filling.INFORMATION_PARTS.establishmentAccountDetails.value
              : this.filling.INFORMATION_PARTS.agencyAccountDetails.value,
        icon: 'account_details',
        ordering: ordering,
        route: 'account-details',
      });
      ordering++;
    }

    if (
      profileType === UserGroups.GINGR &&
      !(
        managedUser &&
        establishmentConnection?.status === EstablishmentConnectionStatus.FREE
      )
    ) {
      returnArray.push({
        service_name: 'languages',
        name: this.translation.translate(_t('ACCOUNT.Nationality & Languages')),
        fill_percent: this.filling.INFORMATION_PARTS.languagesOrigin.value,
        icon: 'languages_origin',
        ordering: ordering,
        route: 'languages',
      });
      ordering++;
    }

    if (
      !(
        profileType === UserGroups.GINGR &&
        managedUser &&
        establishmentConnection?.status === EstablishmentConnectionStatus.FREE
      )
    ) {
      returnArray.push({
        service_name: 'location',
        name: this.translation.translate(_t('ACCOUNT.Location')),
        fill_percent: this.filling.INFORMATION_PARTS.location.value,
        icon: 'location',
        route: 'location',
        ordering: ordering,
        canBeDefault: 'location',
      });
      ordering++;
    }

    returnArray.push({
      service_name: 'photo_video',
      name: this.translation.translate(_t('ACCOUNT.Photos & Videos')),
      fill_percent: this.filling.INFORMATION_PARTS.media.value,
      icon: 'fotos_videos',
      ordering: ordering,
      route: 'media',
    });
    ordering++;

    if (profileType === UserGroups.GINGR) {
      returnArray.push({
        service_name: 'pricing',
        name: this.translation.translate(_t('ACCOUNT.Pricing')),
        fill_percent: this.filling.INFORMATION_PARTS.pricing.value,
        icon: 'incall_outcall_pricing',
        ordering: ordering,
        route: 'pricing',
        canBeDefault: 'isDefaultPricing',
      });
      ordering++;

      returnArray.push({
        service_name: 'services',
        name: this.translation.translate(_t('ACCOUNT.Services')),
        fill_percent: this.filling.INFORMATION_PARTS.services.value,
        icon: 'services',
        ordering: ordering,
        route: 'services',
        canBeDefault: 'isDefaultServices',
      });
      ordering++;
    }

    if (profileType === UserGroups.ESTABLISHMENT) {
      returnArray.push({
        service_name: 'facilities',
        name: this.translation.translate(_t('ACCOUNT.Facilities')),
        fill_percent: this.filling.INFORMATION_PARTS.facilities.value,
        icon: 'bed',
        ordering: ordering,
        route: 'facilities',
      });
      ordering++;

      returnArray.push({
        service_name: 'availability',
        name: this.translation.translate(_t('ACCOUNT.Opening hours')),
        fill_percent: this.filling.INFORMATION_PARTS.availability.value,
        icon: 'availability',
        route: 'opening-hours',
        ordering: ordering,
      });
      ordering++;
    }

    if (profileType === UserGroups.AGENCY) {
      returnArray.push({
        service_name: 'opening-hours',
        name: this.translation.translate(_t('ACCOUNT.Opening hours')),
        fill_percent: this.filling.INFORMATION_PARTS.availability.value,
        icon: 'availability',
        route: 'opening-hours',
        ordering: ordering,
      });
      ordering++;
    }

    return returnArray;
  }

  getOptionalMenu(
    profileType: UserGroups,
    managedUser?: boolean,
    establishmentConnection?: EstablishmentConnection
  ): Array<any> {
    const returnArray = [];
    let ordering = 1;

    if (profileType === UserGroups.GINGR) {
      returnArray.push({
        service_name: 'availability',
        name: this.translation.translate(_t('ACCOUNT.Availability')),
        fill_percent: this.filling.INFORMATION_PARTS.availability.value,
        icon: 'availability',
        ordering: ordering,
        route: 'availability',
        canBeDefault: 'isDefaultAvailability',
      });
      ordering++;

      returnArray.push({
        service_name: 'personal_details',
        name: this.translation.translate(_t('ACCOUNT.Personal Details')),
        fill_percent: this.filling.INFORMATION_PARTS.personalDetails.value,
        icon: 'personal_details',
        ordering: ordering,
        route: 'personal-details',
      });
      ordering++;
    }

    if (
      profileType === UserGroups.ESTABLISHMENT ||
      profileType === UserGroups.AGENCY
    ) {
      returnArray.push({
        service_name: 'extra-details',
        name: this.translation.translate(_t('ACCOUNT.Extra details')),
        fill_percent: this.filling.INFORMATION_PARTS.extraDetails.value,
        icon: 'extra-details-settings',
        ordering: ordering,
        route: 'extra-details',
      });
      ordering++;
    }

    if (profileType === UserGroups.GINGR) {
      returnArray.push({
        service_name: 'verified_user',
        name: this.translation.translate(_t('ACCOUNT.Verification')),
        fill_percent: establishmentConnection?.isManaged
          ? this.filling.INFORMATION_PARTS.connectedGingrVerification.value
          : this.filling.INFORMATION_PARTS.gingrVerification.value,
        icon: 'verified_user',
        ordering: ordering,
        route: 'verification',
      });
      ordering++;
    }

    if (
      profileType === UserGroups.ESTABLISHMENT ||
      profileType === UserGroups.AGENCY
    ) {
      returnArray.push({
        service_name: 'default-gingr-settings',
        name: this.translation.translate(_t('ACCOUNT.Default gingr settings')),
        fill_percent: this.filling.INFORMATION_PARTS.defaultSettings.value,
        icon: 'default-gingr-settings',
        ordering: ordering,
        transparent: true,
        route: 'default-gingr-settings',
      });
      ordering++;
    }

    return returnArray;
  }

  public getGingrMenu(
    type?: string,
    managedUser?: boolean,
    establishmentConnection?: EstablishmentConnection
  ): Array<any> {
    const mandatory: Array<any> = this.getMandatoryMenu(
      UserGroups.GINGR,
      managedUser,
      establishmentConnection
    );
    const optional: Array<any> = this.getOptionalMenu(
      UserGroups.GINGR,
      managedUser,
      establishmentConnection
    );

    if (!type) {
      return mandatory.concat(optional);
    }
    if (type === 'mandatory') {
      return mandatory;
    }
    if (type === 'optional') {
      return optional;
    }
  }

  public getEstablishmentMenu(type?: string): Array<any> {
    const mandatory: Array<any> = this.getMandatoryMenu(
      UserGroups.ESTABLISHMENT
    );
    const optional: Array<any> = this.getOptionalMenu(UserGroups.ESTABLISHMENT);

    if (!type) {
      return mandatory.concat(optional);
    }
    if (type === 'mandatory') {
      return mandatory;
    }
    if (type === 'optional' || !type) {
      return optional;
    }
  }

  public getAgencyMenu(type?: string): Array<any> {
    const mandatory: Array<any> = this.getMandatoryMenu(UserGroups.AGENCY);
    const optional: Array<any> = this.getOptionalMenu(UserGroups.AGENCY);

    if (!type) {
      return mandatory.concat(optional);
    }
    if (type === 'mandatory') {
      return mandatory;
    }
    if (type === 'optional') {
      return optional;
    }
  }
}
