import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgIf, NgClass } from '@angular/common';
import { L10N_LOCALE, L10nLocale, L10nTranslatePipe } from 'angular-l10n';

@Component({
  selector: 'gingr-footer-button',
  templateUrl: './footer-button.component.html',
  styleUrls: ['./footer-button.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, ExtendedModule, L10nTranslatePipe],
})
export class FooterButtonComponent implements OnInit {
  @Input() text: any;
  @Input() cancel: any;
  @Input() disabled = false;
  @Input() noButton = false;
  @Input() defaultButton = false;
  @Input() shadow = true;
  @Input() firstLetterUppercase = false;
  @Input() noBackground = false;
  @Output() clicked = new EventEmitter();
  @Input() fullButton = false;
  @Output() cancelClicked = new EventEmitter();
  @Input() ctaColorsInverted = false;
  @Input() cashValue = null;
  @Input() instaButtonDouble: boolean = false;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnInit(): void {}

  onClick() {
    this.clicked.emit(null);
  }
  onCancelClick() {
    this.cancelClicked.emit(null);
  }
}
