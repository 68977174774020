import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocationService } from '@app/shared/services';
import { filter, map } from 'rxjs/operators';
import { SummaryCoordinates } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class LocationGuard  {
  constructor(private locationService: LocationService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.locationService.currentDeviceLocation.pipe(
      filter((location: SummaryCoordinates) => !!location),
      map(() => true)
    );
  }
}
