export interface TransactionModel {
  id: number;
  amount: number;
  deducted: boolean;
  dateCreated: string;
  transactionType: TransactionType;
  paymentMethod?: PaymentMethod;
  transactionStatus?: TransactionStatus;
  icon?: string;
}

export enum TransactionType {
  Welcome = 'Welcome',
  Support = 'Support',
  TopUp = 'TopUp',
  ServiceFee = 'ServiceFee',
  ServiceFeeRefund = 'ServiceFeeRefund',
  CompensationRefund = 'CompensationRefund',
  Referral = 'Referral',
  CancellationFee = 'CancellationFee',
  BalanceSettlement = 'BalanceSettlement',
  Payout = 'Payout',
}

export enum PaymentMethod {
  BankTransfer = 'BankTransfer',
  PaymentSlip = 'PaymentSlip',
  Cash = 'Cash',
  Card = 'Card',
}

export enum TransactionStatus {
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Rejected = 'Rejected',
}
