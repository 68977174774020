import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import {
  IsActiveMatchOptions,
  NavigationStart,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { UserAvailableRoles } from '@app/shared/models';
import { SvgIconComponent } from 'angular-svg-icon';
import { L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';
import { MatIconModule } from '@angular/material/icon';
import { CurrentUser } from '@app/shared/models/current-user';
import { filter } from 'rxjs';
import { Store } from '@ngrx/store';
import { IntercomService } from '@app/shared/services/intercom.service';
import { UserRoles } from '@app/shared/models/enum/userroles';
import { StaticUtilsService } from '@app/shared/services/static-utils.service';
import { FeatureFlags } from '@app/shared/models/constants';

@Component({
  selector: 'gingr-sidenav-desktop',
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    RouterLink,
    SvgIconComponent,
    L10nTranslatePipe,
    MatIconModule,
    RouterLinkActive,
  ],
  templateUrl: './sidenav-desktop.component.html',
  styleUrls: ['./sidenav-desktop.component.scss'],
})
export class SidenavDesktopComponent implements OnInit {
  @Input() user: CurrentUser;
  @Input() avatar: string;
  @Input() isProUser: boolean;
  @Input() featurePageSubscription: boolean;
  @Input() baseRole: string;
  @Input() featureHasGGCoin: boolean;
  @Input() showBuildId: boolean;
  @Input() releaseId: string;

  @Output() logoutEvent = new EventEmitter();
  whatever: IsActiveMatchOptions;

  readonly locale = inject(L10N_LOCALE);
  readonly router = inject(Router);
  readonly store = inject(Store);
  readonly intercomService = inject(IntercomService);

  extraSidebarVisible = false;
  settingsExpanded = false;
  profileExpanded = false;
  referralExpanded = false;
  isAccountRoute = false;
  userAvailableRoles = UserAvailableRoles;
  userRoles = UserRoles;
  hideReviews = false;

  async ngOnInit() {
    await StaticUtilsService.LdClient.waitUntilReady().then();
    this.hideReviews = StaticUtilsService.LdClient.variation(
      FeatureFlags.HideClientReviews,
      false
    );
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: any) => {
        this.isAccountRoute = event.url.startsWith('/account');
        this.profileExpanded = false;
        this.settingsExpanded = false;
        this.extraSidebarVisible = false;
        this.referralExpanded = false;
      });
  }

  openIntercomSupport() {
    this.intercomService.intercom(true);
  }
}
