import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationService } from '@app/shared/services';
import { ToastModel, ToastType } from '@app/shared/models/toast.model';
import { Router } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'gingr-toast-show',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './toast-show.component.html',
  styleUrls: ['./toast-show.component.scss'],
})
export class ToastShowComponent {
  readonly notificationService = inject(NotificationService);
  readonly router = inject(Router);

  toastList = this.notificationService.toastList;

  toastStyles = {};

  constructor() {
    this.toastStyles[ToastType.Success] = {
      icon: '/assets/svg-icons/common/status-success-circle.svg',
      iconColor: '#00FF00',
    };
    this.toastStyles[ToastType.Error] = {
      icon: '/assets/svg-icons/common/status-error-circle.svg',
      iconColor: '#FF0015',
    };
    this.toastStyles[ToastType.Warning] = {
      icon: '/assets/svg-icons/common/status-alert-circle.svg',
      iconColor: '#FFD640',
    };
    this.toastStyles[ToastType.Info] = {
      icon: '/assets/svg-icons/common/status-info-circle.svg',
      iconColor: '#F49FFC',
    };
  }

  clickToast(toast: ToastModel) {
    const redirectUrl = toast.redirectLink;
    this.notificationService.removeToast(toast);

    if (redirectUrl) {
      this.router.navigate([redirectUrl], toast.redirectParams);
    }
  }
}
