import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@app/shared/services/config.service';
import { Observable } from 'rxjs';
import { ConversationRequest } from '@app/shared/models/conversation/conversation-request';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  constructor(public http: HttpClient, public configService: ConfigService) {}

  getToken(query: ConversationRequest): Observable<{ token: string }> {
    return this.http.get<{ token: string }>(
      `${this.configService.config.apiV3}/conversation/token?instanceType=${query.instanceType}&instanceId=${query.instanceId}`
    );
  }

  endConversation(query: ConversationRequest) {
    return this.http.post(
      `${this.configService.config.apiV3}/conversation/deleteInstafuckConversation?instanceType=${query.instanceType}&instanceId=${query.instanceId}`,
      null
    );
  }
}
