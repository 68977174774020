import { Injectable } from '@angular/core';
import { Observer, Observable, of } from 'rxjs';
import { setBookTravelDistanceAction } from '@app/book/store/book.actions';
import { Store } from '@ngrx/store';
import { saveTravelDistance } from '@app/instafeature/store/instafeature.actions';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BookingDistancePayload } from '@app/shared/models/booking-distance-payload';
import { ConfigService } from '@app/shared/services/config.service';
import { BookingDistance } from '@app/shared/models/booking-distance';

@Injectable({
  providedIn: 'root',
})
export class GeocodingService {
  geocoder: google.maps.Geocoder;

  constructor(
    private store: Store,
    private http: HttpClient,
    private configService: ConfigService
  ) {}

  LatLng(lat: number, lng: number): any {
    return new google.maps.LatLng(lat, lng);
  }

  geocode(
    latLng: google.maps.LatLng
  ): Observable<google.maps.GeocoderResult[]> {
    return new Observable(
      (observer: Observer<google.maps.GeocoderResult[]>) => {
        // Invokes geocode method of Google Maps API geocoding.
        this.geocoder = new google.maps.Geocoder();
        this.geocoder.geocode(
          { location: latLng },
          (
            results: google.maps.GeocoderResult[],
            status: google.maps.GeocoderStatus
          ) => {
            if (status === google.maps.GeocoderStatus.OK) {
              observer.next(results);
              observer.complete();
            } else {
              observer.error(status);
            }
          }
        );
      }
    );
  }

  codeAddress(address: string): Observable<google.maps.GeocoderResult[]> {
    return new Observable(
      (observer: Observer<google.maps.GeocoderResult[]>) => {
        // Invokes geocode method of Google Maps API geocoding.
        this.geocoder = new google.maps.Geocoder();
        this.geocoder.geocode(
          { address: address },
          (
            results: google.maps.GeocoderResult[],
            status: google.maps.GeocoderStatus
          ) => {
            if (status === google.maps.GeocoderStatus.OK) {
              observer.next(results);
              observer.complete();
            } else {
              observer.error(status);
            }
          }
        );
      }
    );
  }

  async randomizeLocationInRadius(
    initialLatitude: number,
    initialLongitude: number,
    radiusInKilometers: number = 0.05
  ) {
    // Convert latitude and longitude from degrees to radians
    const latInRadians = (initialLatitude * Math.PI) / 180;
    const lonInRadians = (initialLongitude * Math.PI) / 180;

    // Earth's radius in kilometers
    const earthRadius = 6371;

    // Convert radius from kilometers to radians
    const radiusInRadians = radiusInKilometers / earthRadius;

    // Generate a random angle in radians
    const randomAngle = Math.random() * 2 * Math.PI;

    // Generate a random radius in radians
    const randomRadius = Math.random() * radiusInRadians;

    // Calculate the new latitude
    const newLatInRadians = Math.asin(
      Math.sin(latInRadians) * Math.cos(randomRadius) +
        Math.cos(latInRadians) * Math.sin(randomRadius) * Math.cos(randomAngle)
    );

    // Calculate the new longitude
    const newLonInRadians =
      lonInRadians +
      Math.atan2(
        Math.sin(randomAngle) * Math.sin(randomRadius) * Math.cos(latInRadians),
        Math.cos(randomRadius) -
          Math.sin(latInRadians) * Math.sin(newLatInRadians)
      );

    // Convert the new latitude and longitude from radians to degrees
    const newLatitude = (newLatInRadians * 180) / Math.PI;
    const newLongitude = (newLonInRadians * 180) / Math.PI;
    return await this.decodeAddressByLocation(
      new google.maps.LatLng({ lat: newLatitude, lng: newLongitude })
    );
  }

  async decodeAddressByLocation(location: google.maps.LatLng) {
    return await this.geocoder.geocode({ location }, null);
  }

  getDistanceFromLatLonInKm(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lon2 - lon1);
    const compute =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    return R * 2 * Math.atan2(Math.sqrt(compute), Math.sqrt(1 - compute));
  }

  deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }

  getDrivingDistanceBetweenLocations(
    lat1: number,
    long1: number,
    lat2: number,
    long2: number,
    saveInsta: boolean = false
  ) {
    const request: BookingDistancePayload = {
      sourceLatitude: lat1,
      sourceLongitude: long1,
      destinationLatitude: lat2,
      destinationLongitude: long2,
      isInstantBooking: saveInsta,
    };

    this.http
      .post<BookingDistance>(
        `${this.configService.config.apiV3}/instant-booking/distance`,
        request
      )
      .pipe(catchError(() => of(null)))
      .subscribe((response: BookingDistance) => {
        const travelDistance = response.distance;
        const distanceId = response.distanceId;
        if (!saveInsta) {
          this.store.dispatch(
            setBookTravelDistanceAction({
              travelDistance,
              distanceId,
            })
          );
          return;
        }

        this.store.dispatch(
          saveTravelDistance({
            travelDistance,
            distanceId,
          })
        );
      });
  }
}
