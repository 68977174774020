export enum Constraints {
  CURRENT_LOCATION = 'userLocation',
  CLIENT_LOCATION = 'clientLocation',
  STUDIO_LOCATION = 'studioLocation',
  BOTH_LOCATION = 'both',
}

export const STORAGE_NAMES = {
  CURRENT_USER: 'currentUser',
  CONNECT_TYPE: 'connectType',
  FILTER: 'filter',
  PROFILE: 'profile',
  ALL_PROFILES: 'allProfiles',
  LOCATION: 'location',
  CONFIG: 'config',
  DIRECTIONS: 'route',
  PRICE: 'price',
  HOURS: 'hours',
  TOKEN: 'token',
  CLIENT: 'client',
  BOOKING_ID: 'booking',
  BOOKING: 'booking_data',
};

export const DIALOG_CONFIG = {
  position: {
    top: '0px',
    right: '0px',
  },
  height: '100%',
  width: '100vw',
  data: {},
  autoFocus: false,
  panelClass: 'full-screen-modal',
  maxWidth: '100%',
};

export const ONE_MINUTE = 60000;
export const FIVE_MINUTES = 300000;
