import { ServiceProvidersSearch } from '@app/instafeature/models/service-provider-search';
import { ServiceProviders } from '@app/instafeature/models/service-provider';
import { createReducer, on } from '@ngrx/store';
import {
  deleteActiveBooking,
  lastStepCompletedAction,
  openInstaMap,
  saveClientCountInArea,
  saveDirections,
  saveGingrCountInArea,
  saveInstaState,
  saveIntervalsExtend,
  saveProfile,
  saveServiceProviders,
  saveTravelDistance,
  saveUnreadMessagesNumber,
  saveUserConnected,
  setEmptySelectedServices,
  setExtensionTimeSlot,
  setExtensionWizard,
  setInstaStepAction,
  setStartedInstaFromAction,
} from './instafeature.actions';
import { GingrCountingData } from '@app/instafeature/models/gingr-counting-data';
import { InstaState, InstaSteps } from '@app/instafeature/models/insta-state';
import { StartedInstaFromEnum } from '@app/instafeature/models/started-insta-from.enum';

export interface InstaFeatureState {
  clients: number;
  gingrs: GingrCountingData;
  serviceProviders: ServiceProvidersSearch[];
  directions?: any;
  profile: ServiceProviders | null;
  instaState: InstaState;
  step?: number;
  unreadMessagesNumber: number;
  userConnected: boolean;
  lastStepCompleted: InstaSteps;
  instaMapOpen: boolean;
  maxDurationExtend: number;
  startedInstaFrom: StartedInstaFromEnum;
  extensionWizard: boolean;
}

export const initialInstaFeatureState: InstaFeatureState = {
  clients: undefined,
  gingrs: undefined,
  serviceProviders: undefined,
  profile: undefined,
  instaState: undefined,
  step: undefined,
  unreadMessagesNumber: 0,
  userConnected: undefined,
  lastStepCompleted: InstaSteps.Empty,
  instaMapOpen: false,
  maxDurationExtend: undefined,
  startedInstaFrom: undefined,
  extensionWizard: false,
};

export const instafeatureReducer = createReducer(
  initialInstaFeatureState,
  on(setInstaStepAction, (state, { step }) => ({
    ...state,
    step,
  })),
  on(saveUserConnected, (state, { userConnected }) => ({
    ...state,
    userConnected,
  })),
  on(saveUnreadMessagesNumber, (state, { unreadMessagesNumber }) => ({
    ...state,
    unreadMessagesNumber,
  })),
  on(saveProfile, (state, { profile }) => ({
    ...state,
    profile,
  })),
  on(saveServiceProviders, (state, { serviceProviders }) => ({
    ...state,
    serviceProviders,
  })),
  on(saveClientCountInArea, (state, { clients }) => {
    return {
      ...state,
      clients,
    };
  }),
  on(saveGingrCountInArea, (state, { gingrs }) => ({
    ...state,
    gingrs,
  })),
  on(saveInstaState, (state, { instaState }) => ({
    ...state,
    instaState,
  })),
  on(saveDirections, (state, { directions }) => ({
    ...state,
    directions,
  })),
  on(lastStepCompletedAction, (state, { lastStepCompleted }) => ({
    ...state,
    lastStepCompleted,
  })),
  on(setStartedInstaFromAction, (state, { startedInstaFrom }) => ({
    ...state,
    startedInstaFrom,
  })),
  on(deleteActiveBooking, (state) => ({
    ...state,
    instaState: undefined,
    userConnected: false,
  })),
  on(openInstaMap, (state) => ({
    ...state,
    instaMapOpen: !state.instaMapOpen,
  })),
  on(saveIntervalsExtend, (state, { maxDuration }) => ({
    ...state,
    maxDurationExtend: maxDuration,
  })),
  on(saveTravelDistance, (state, { travelDistance, distanceId }) => ({
    ...state,
    instaState: {
      ...state.instaState,
      travelDistance,
      distanceId,
    },
  })),
  on(setExtensionWizard, (state, { extensionWizard }) => ({
    ...state,
    extensionWizard: extensionWizard,
    instaState: {
      ...state.instaState,
      skipRedirect: true,
    },
  })),
  on(setExtensionTimeSlot, (state, { extensionTimeSlot }) => ({
    ...state,
    instaState: {
      ...state.instaState,
      extensionPriceInterval: extensionTimeSlot,
    },
  })),
  on(setEmptySelectedServices, (state) => ({
    ...state,
    instaState: {
      ...state.instaState,
      services: [],
      servicesPrice: 0,
    },
  }))
);
