export interface DataSpikeVerificationModel {
  status: DataSpikeVerificationStatus;
  createdAt: string;
  completedAt?: string;
  verificationUrlId: string;
  expiresAt: string;
}

export enum DataSpikeVerificationStatus {
  Initial = 'initial',
  Pending = 'pending',
  InProgress = 'in_progress',
  Verified = 'verified',
  Failed = 'failed',
  Canceled = 'canceled',
  Expired = 'expired',
}
