export class DeviceChecker {

  static isIOS(): boolean {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }

  static isSafari(): boolean {
    return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  }

  static isAndroid() {
    return navigator.userAgent.match(/Android/i);
  }

  static isBlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i);
  }

  static isOpera() {
    return navigator.userAgent.match(/Opera Mini/i);
  }

  static isWindowsMobile() {
    return navigator.userAgent.match(/IEMobile/i);
  }

  static isMobile() {
    return this.isAndroid() || this.isBlackBerry() || this.isIOS() || this.isOpera() || this.isWindowsMobile();
  }

  static checkIosVersion() {
    const agent = window.navigator.userAgent,
      start = agent.indexOf('OS');
    if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
      return Number(agent.substr(start + 3, 3).replace('_', '.'));
    }
    return 0;
  }
}
