<div
  class="toast toast-top toast-end z-[10000] whitespace-normal absolute top-[64px]"
  [class.hidden]="!toastList()?.length"
>
  <ng-container *ngFor="let toast of toastList()">
    <a
      class="block relative bg-[#A00B95] border-0 rounded shadow-md px-5 py-4 pb-[22px] max-w-[calc(100vw-40px)] min-w-[calc(100vw-40px)] md:max-w-[350px] md:min-w-[350px] cursor-pointer"
      (click)="clickToast(toast)"
    >
      <div class="flex flex-row items-start h-full w-full max-w-full">
        <div
          class="flex-none w-10 h-10 mr-4 rounded-full bg-[#A00B95] shadow-xs"
        >
          <svg-icon
            [src]="toastStyles[toast.type].icon"
            [class]="'shadow'"
            [svgStyle]="{
              'width.px': 40,
              'height.px': 40,
              fill: toastStyles[toast.type].iconColor
            }"
          ></svg-icon>
        </div>
        <div class="flex-auto content-center min-h-[40px]">
          <div class="block text-sm text-white">{{ toast.message }}</div>
        </div>
      </div>

      <div
        *ngIf="toast.timeout"
        class="absolute w-100 h-[6px] bg-base-100 left-0 bottom-0 rounded-b-lg"
      >
        <div
          class="h-[6px] bg-base-200 rounded-b-lg animate-w-0-100"
          [style.animation-duration]="toast.timeout + 'ms'"
        ></div>
      </div>
    </a>
  </ng-container>
</div>
