import { createSelector } from '@ngrx/store';
import { InstaFeatureState } from '@app/instafeature/store/instafeature.reducer';
import { ConnectType } from '@app/instafeature/models/connect-type';
import { AppConfig, AppConfigItem } from '@app/shared/models';
import { SharedState } from '@app/shared/reducers/shared.reducer';
import {
  calculateAmountBeforeTax,
  calculateIncludedTax,
} from '@app/shared/helpers/math.helper';

export const getServiceFeeValue = createSelector(
  getSharedState,
  (state: SharedState) =>
    +state?.appConfig?.generalProperties?.find(
      (item: AppConfigItem) => item.name === AppConfig.ESCORT_FEE_INSTA
    )?.value || 0
);

export function getInstaFeatureState(state): InstaFeatureState {
  return state.instafeature;
}

export function getSharedState(state): SharedState {
  return state.shared;
}

export const getClientsCount = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state?.clients
);

export const getGingrCount = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state?.gingrs
);

export const getCurrentServiceProviders = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state?.serviceProviders
);

export const getDirectionsSelector = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state?.directions
);

export const getProfileSelector = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state?.profile
);

export const instafeatureModuleLoaded = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state?.instaState !== undefined
);

export const getInstaStateSelector = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state?.instaState
);

export const getInstaStep = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state.step
);

export const getInstaUnreadMessagesNumber = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state?.unreadMessagesNumber
);

export const getProviderServices = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state.profile?.services || []
);

export const getProviderPrices = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) =>
    state.instaState?.callType === ConnectType.INCALL
      ? state.profile?.pricing?.incall?.intervals
      : state.profile?.pricing?.outcall?.intervals
);

export const getUserConnected = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state.userConnected
);

export const getDuringBookingInstaServicesPrice = createSelector(
  getInstaFeatureState,
  getServiceFeeValue,
  (state: InstaFeatureState, serviceFeeRate) => {
    if (!state.instaState) {
      return;
    }
    const serviceFeeIncluded = state.instaState?.id
      ? state.instaState.serviceFeeIncluded || false
      : state.profile?.serviceFeeIncluded;

    let price = 0;
    if (state?.instaState?.instantBookingServices) {
      price =
        state?.instaState?.instantBookingServices
          ?.filter((service: any) => service.price > 0 && !service.extension)
          ?.reduce(
            (acc, current) => acc + current.price * current.quantity,
            0
          ) || 0;
    } else {
      price = state.instaState.servicesPrice;
    }

    if (!serviceFeeIncluded || !price) {
      return price;
    }
    return Math.floor(calculateAmountBeforeTax(price, serviceFeeRate));
  }
);

export const getBookingExtensionsServicesPrice = createSelector(
  getInstaFeatureState,
  getDuringBookingInstaServicesPrice,
  getServiceFeeValue,
  (state: InstaFeatureState, bookingServicesPrice, serviceFeeRate) => {
    const serviceFeeIncluded = state.instaState.id
      ? state.instaState.serviceFeeIncluded || false
      : state.profile.serviceFeeIncluded;

    let extensionServicesPrice = 0;
    if (!serviceFeeIncluded) {
      extensionServicesPrice =
        state.instaState.extensionsDetails?.reduce(
          (acc, current) => acc + current.servicesPrice,
          0
        ) || 0;
      return Math.round(extensionServicesPrice + bookingServicesPrice);
    }

    extensionServicesPrice =
      state.instaState.extensionsDetails?.reduce(
        (acc, current) =>
          acc +
          Math.floor(
            calculateAmountBeforeTax(current.servicesPrice, serviceFeeRate)
          ),
        0
      ) || 0;
    return Math.round(bookingServicesPrice + extensionServicesPrice);
  }
);

export const originalBookingServicesPrice = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) =>
    state?.instaState?.instantBookingServices
      ?.filter((service) => service.price > 0 && !service.extension)
      ?.reduce((acc, current) => acc + current.price * current.quantity, 0) || 0
);

export const getLastStepCompleted = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state?.lastStepCompleted
);

export const getInstaMapOpen = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state?.instaMapOpen
);

export const getStartedInstaFrom = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state?.startedInstaFrom
);

export const getTravelPrice = createSelector(
  getSharedState,
  getInstaFeatureState,
  (sharedState, state) => {
    const travelPricePerKm =
      +sharedState?.appConfig?.generalProperties?.find(
        (item: AppConfigItem) => item.name === AppConfig.TRAVEL_PRICE_PER_KM
      )?.value || 0;

    if (!state?.instaState || !travelPricePerKm) {
      return 0;
    }
    if (state.instaState.id) {
      return Math.round(state.instaState.travelPriceTotal) || 0;
    }
    if ((state.instaState.travelDistance || 0) < 1) {
      return 0;
    }

    return Math.max(
      Math.round(travelPricePerKm * state.instaState.travelDistance),
      +sharedState.appConfig?.generalProperties?.find(
        (item: AppConfigItem) => item.name === AppConfig.TRAVEL_PRICE_MIN
      )?.value || 0
    );
  }
);

export const getNetTravelPrice = createSelector(
  getInstaFeatureState,
  getTravelPrice,
  getServiceFeeValue,
  (state, travelPrice, serviceFeeRate) => {
    const serviceFeeIncluded = state.instaState.id
      ? state.instaState.serviceFeeIncluded || false
      : state.profile.serviceFeeIncluded;

    if (!serviceFeeIncluded || !travelPrice) {
      return travelPrice;
    }

    return Math.floor(calculateAmountBeforeTax(travelPrice, serviceFeeRate));
  }
);

export const getTotalPrice = createSelector(
  getInstaFeatureState,
  originalBookingServicesPrice,
  getTravelPrice,
  (state, duringBookingInstaServicesPrice, travelPrice) => {
    if (!state?.instaState) {
      return 0;
    }

    const initialPrice =
      (state.instaState.id
        ? state.instaState.price
        : state.instaState.priceInterval?.amount) || 0;
    const servicesPrice =
      (state.instaState.id
        ? duringBookingInstaServicesPrice
        : state.instaState.servicesPrice) || 0;

    return initialPrice + servicesPrice + travelPrice;
  }
);

export const getExtensionsTotalPrice = createSelector(
  getInstaFeatureState,
  getDuringBookingInstaServicesPrice,
  getServiceFeeValue,
  (state, duringBookingInstaServicesPrice, serviceFee) => {
    if (!state?.instaState) {
      return 0;
    }

    return (
      state.instaState.extensionsDetails?.reduce(
        (acc, current) => acc + current.timePrice + current.servicesPrice,
        0
      ) || 0
    );
  }
);

export const getServiceFee = createSelector(
  getInstaFeatureState,
  getTotalPrice,
  getServiceFeeValue,
  getProviderServices,
  getTravelPrice,
  (state, totalPrice, serviceFeeRate, providerServices, travelPrice) => {
    if (!totalPrice || !state.instaState) {
      return 0;
    }
    if (!state.instaState.id && !state.profile) {
      return 0;
    }

    const serviceFeeIncluded = state.instaState.id
      ? state.instaState.serviceFeeIncluded || false
      : state.profile.serviceFeeIncluded;

    let serviceFee = 0;
    const initialPrice =
      (state.instaState.id
        ? state.instaState.price
        : state.instaState.priceInterval?.amount) || 0;

    if (serviceFeeIncluded) {
      serviceFee += Math.ceil(
        calculateIncludedTax(initialPrice, serviceFeeRate)
      );
      if (state?.instaState?.instantBookingServices?.length > 0) {
        serviceFee +=
          state?.instaState?.instantBookingServices
            ?.filter((service) => service.price > 0 && !service.extension)
            ?.reduce(
              (acc, current) =>
                acc +
                Math.ceil(calculateIncludedTax(current.price, serviceFeeRate)),
              0
            ) || 0;
      }
      if (state.instaState.services?.length) {
        state.instaState.services.forEach((id) => {
          const servicePrice = providerServices.find(
            (service) => service.id === id
          )?.price;
          if (servicePrice) {
            serviceFee += Math.ceil(
              calculateIncludedTax(servicePrice, serviceFeeRate)
            );
          }
        });
      }
      if (travelPrice) {
        serviceFee += Math.ceil(
          calculateIncludedTax(travelPrice, serviceFeeRate)
        );
      }
      if (state.instaState.extensionsDetails?.length) {
        state.instaState.extensionsDetails.forEach((item) => {
          serviceFee +=
            Math.ceil(
              calculateIncludedTax(item.servicesPrice, serviceFeeRate)
            ) + Math.ceil(calculateIncludedTax(item.timePrice, serviceFeeRate));
        });
      }
    } else {
      serviceFee += Math.round((totalPrice * serviceFeeRate) / 100);
      if (state.instaState.extensionsDetails?.length) {
        state.instaState.extensionsDetails.forEach((item) => {
          serviceFee += Math.round(
            calculateIncludedTax(
              item.servicesPrice + item.timePrice,
              serviceFeeRate
            )
          );
        });
      }
    }

    return Math.round(serviceFee);
  }
);

export const getTotalPriceWithTax = createSelector(
  getInstaFeatureState,
  getTotalPrice,
  getServiceFee,
  getExtensionsTotalPrice,
  (state, totalPrice, serviceFee, extensionsPrice) => {
    if (!state.instaState) {
      return 0;
    }
    if (!state.instaState.id && !state.profile) {
      return 0;
    }

    const serviceFeeIncluded = state.instaState.id
      ? state.instaState.serviceFeeIncluded || false
      : state.profile.serviceFeeIncluded;

    if (serviceFeeIncluded) {
      return totalPrice + extensionsPrice;
    } else {
      return totalPrice + extensionsPrice + serviceFee;
    }
  }
);

export const getInitialBookingPrice = createSelector(
  getInstaFeatureState,
  getServiceFeeValue,
  (state, serviceFeeRate) => {
    const serviceFeeIncluded = state.instaState.id
      ? state.instaState.serviceFeeIncluded || false
      : state.profile.serviceFeeIncluded;
    const price =
      state.instaState.priceInterval?.amount || state.instaState.price || 0;

    if (!serviceFeeIncluded || !price) {
      return price;
    }

    return Math.floor(calculateAmountBeforeTax(price, serviceFeeRate));
  }
);

export const maxDurationExtend = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state?.maxDurationExtend
);

export const extensionWizard = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state?.extensionWizard
);

export const extensionTimeSlot = createSelector(
  getInstaFeatureState,
  (state: InstaFeatureState) => state?.instaState?.extensionPriceInterval
);
