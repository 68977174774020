import * as profile from '../actions/profile.actions';

export interface State {
  ids: number[];
  loading: boolean;
  query: string;
}

const initialState: State = {
  ids: [],
  loading: false,
  query: '',
};

export function searchReducer(
  state = initialState,
  action: profile.Actions
): State {
  switch (action.type) {
    case profile.SEARCH_COMPLETE: {
      const profiles = action.payload;

      return {
        ids: profiles.map((pr) => pr.id),
        loading: false,
        query: state.query,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIds = (state: State) => state.ids;

export const getQuery = (state: State) => state.query;

export const getLoading = (state: State) => state.loading;
