import { OutcallType } from '@app/instafeature/models/outcall-type';
import {
  BookingPartner,
  Client,
  InstaAddress,
} from '@app/instafeature/models/booking-data';
import { ConnectType } from '@app/instafeature/models/connect-type';
import {
  Service,
  ServiceProviders,
} from '@app/instafeature/models/service-provider';
import { LocationModel } from '@app/instafeature/models/location';
import { PriceInterval } from '@app/shared/models/grid/grid-user-details';
import { ExtensionDetails } from '@app/instafeature/models/extension-details';

export interface InstaState {
  stepId: INSTA_STEP;
  id?: number;
  clientId?: number;
  serviceProviderId?: number;
  bookedDuration?: number;
  priceInterval?: PriceInterval;
  paymentInfo?: boolean;
  message?: string;
  startTime?: string;
  endTime?: string;
  state?: string;
  price?: number;
  extensionPrice?: number;
  services?: number[];
  servicesPrice?: number;
  currency?: string;
  callType?: ConnectType;
  outcallType?: OutcallType;
  travelType?: google.maps.TravelMode;
  serviceProvider?: ServiceProviders;
  client?: Client;
  bookingPartnerDto?: BookingPartner;
  createdAt?: string;
  updatedAt?: string;
  pausedAt?: any;
  extendedTime?: number;
  cancellationComment?: string;
  cancellationReason?: string;
  endedEarlyComment?: string;
  endedEarlyReason?: string;
  estimatedArrival?: Date;
  address?: InstaAddress;
  travelDistance: number;
  travelPriceTotal: number;
  serviceFeeIncluded?: boolean;
  skipRedirect?: boolean;
  gingrCallType?: ConnectType;
  instantBookingServices?: Service[];
  currentUserConnected?: boolean;
  travellerInitialLocation?: LocationModel;
  extensionPriceInterval?: PriceInterval;
  extensionState?: ExtensionState;
  extensionServices?: number[];
  extensionPriceIntervalId?: number;
  extensionDuration?: number;
  extensionsDetails?: ExtensionDetails[];
  distanceId?: string;
  cashBookingAvailable?: boolean;
}

export enum ExtensionState {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Declined = 'Declined',
}

export enum INSTA_STEP {
  GingrInitial = 'GingrInitial',
  GingrFindIncall = 'GingrFindIncall',
  GingrFindOutcall = 'GingrFindOutcall',
  GingrFindBoth = 'GingrFindBoth',
  GingrIncallHasRequest = 'GingrIncallHasRequest',
  GingrIncallWaitingTraveler = 'GingrIncallWaitingTraveler',
  GingrIncallTravelerArrived = 'GingrIncallTravelerArrived',
  GingrIncallInSession = 'GingrIncallInSession',
  GingrIncallSessionEnded = 'GingrIncallSessionEnded',
  GingrIncallSessionCancelled = 'GingrIncallSessionCancelled',
  GingrOutcallHasRequest = 'GingrOutcallHasRequest',
  GingrOutcallTraveling = 'GingrOutcallTraveling',
  GingrOutcallArrived = 'GingrOutcallArrived',
  GingrOutcallInSession = 'GingrOutcallInSession',
  GingrOutcallSessionEnded = 'GingrOutcallSessionEnded',
  GingrOutcallSessionCancelled = 'GingrOutcallSessionCancelled',
  GingrBothHasRequest = 'GingrBothHasRequest',
  GingrIncallSessionPaused = 'GingrIncallSessionPaused',
  GingrOutcallSessionPaused = 'GingrOutcallSessionPaused',
  ClientInitial = 'ClientInitial',
  ClientSearchIncall = 'ClientSearchIncall',
  ClientOutcallAddress = 'ClientOutcallAddress',
  ClientSearchOutcall = 'ClientSearchOutcall',
  ClientIncallGingrOverview = 'ClientIncallGingrOverview',
  ClientIncallChooseTravel = 'ClientIncallChooseTravel',
  ClientIncallPayment = 'ClientIncallPayment',
  ClientIncallBookingDetails = 'ClientIncallBookingDetails',
  ClientIncallRequested = 'ClientIncallRequested',
  ClientIncallTraveling = 'ClientIncallTraveling',
  ClientIncallArrived = 'ClientIncallArrived',
  ClientIncallInSession = 'ClientIncallInSession',
  ClientIncallSessionEnded = 'ClientIncallSessionEnded',
  ClientIncallSessionCancelled = 'ClientIncallSessionCancelled',
  ClientOutcallGingrOverview = 'ClientOutcallGingrOverview',
  ClientOutcallChooseTravel = 'ClientOutcallChooseTravel',
  ClientOutcallPayment = 'ClientOutcallPayment',
  ClientOutcallBookingDetails = 'ClientOutcallBookingDetails',
  ClientOutcallRequested = 'ClientOutcallRequested',
  ClientOutcallWaitingTraveler = 'ClientOutcallWaitingTraveler',
  ClientOutcallTravelerArrived = 'ClientOutcallTravelerArrived',
  ClientOutcallInSession = 'ClientOutcallInSession',
  ClientOutcallSessionEnded = 'ClientOutcallSessionEnded',
  ClientOutcallSessionCancelled = 'ClientOutcallSessionCancelled',
}

export enum InstaSteps {
  BookingDetails = 0,
  Payment = 1,
  Empty = 2,
}
