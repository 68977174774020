import { Action } from '@ngrx/store';
import { Profile } from '../../shared/models';

export const ADD_PROFILE = '[Collection] Add Profile';
export const ADD_PROFILE_SUCCESS = '[Collection] Add Profile Success';
export const ADD_PROFILE_FAIL = '[Collection] Add Profile Fail';
export const REMOVE_PROFILE = '[Collection] Remove Profile';
export const REMOVE_PROFILE_SUCCESS = '[Collection] Remove Profile Success';
export const REMOVE_PROFILE_FAIL = '[Collection] Remove Profile Fail';
export const LOAD = '[Collection] Load';
export const LOAD_SUCCESS = '[Collection] Load Success';
export const LOAD_FAIL = '[Collection] Load Fail';

/**
 * Add Profile to Collection Actions
 */
export class AddProfileAction implements Action {
  readonly type = ADD_PROFILE;

  constructor(public payload: Profile) {}
}

export class AddProfileSuccessAction implements Action {
  readonly type = ADD_PROFILE_SUCCESS;

  constructor(public payload: Profile) {}
}

export class AddProfileFailAction implements Action {
  readonly type = ADD_PROFILE_FAIL;

  constructor(public payload: Profile) {}
}

/**
 * Remove Profile from Collection Actions
 */
export class RemoveProfileAction implements Action {
  readonly type = REMOVE_PROFILE;

  constructor(public payload: Profile) {}
}

export class RemoveProfileSuccessAction implements Action {
  readonly type = REMOVE_PROFILE_SUCCESS;

  constructor(public payload: Profile) {}
}

export class RemoveProfileFailAction implements Action {
  readonly type = REMOVE_PROFILE_FAIL;

  constructor(public payload: Profile) {}
}

/**
 * Load Collection Actions
 */
export class LoadAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: Profile[]) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;

  constructor(public payload: any) {}
}

export type Actions =
  | AddProfileAction
  | AddProfileSuccessAction
  | AddProfileFailAction
  | RemoveProfileAction
  | RemoveProfileSuccessAction
  | RemoveProfileFailAction
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction;
