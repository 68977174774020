<div
  class="insta-footer bg-base-300 py-3 px-5 fixed xl:absolute xl:rounded-b xl:px-[65px]"
  *ngIf="!noButton"
  [ngClass]="{ flex: !text && cancel, shadow: shadow }"
  [class.noBackground]="noBackground"
  [class.fullButton]="fullButton"
>
  <div
    class="flex flex-col justify-center items-center mb-2.5"
    *ngIf="cashValue"
  >
    <div class="text-white text-lg">{{ cashValue }} CHF</div>
    <div class="text-primary text-sm">
      {{ 'BOOKINGS.This escort accepts cash' | translate: locale.language }}
    </div>
  </div>
  <button
    *ngIf="text && !cancel"
    [disabled]="disabled"
    [class.btn-primary]="!defaultButton"
    [class.text-white]="defaultButton"
    class="btn items-center w-full rounded text-md font-semibold"
    (click)="onClick()"
  >
    <span
      class="text-md font-semibold"
      [ngClass]="{
        disabled: disabled,
        'lowercase first-letter:uppercase': firstLetterUppercase,
        capitalize: !firstLetterUppercase
      }"
      >{{ text }}</span
    >
  </button>
  <button
    *ngIf="!text && cancel"
    [disabled]="disabled"
    class="btn btn-secondary items-center w-full rounded text-md font-semibold"
    (click)="onCancelClick()"
    [class.text-white]="instaButtonDouble"
  >
    <span
      class="text-md font-semibold"
      [ngClass]="{
        'lowercase first-letter:uppercase': firstLetterUppercase,
        capitalize: !firstLetterUppercase
      }"
    >
      {{ cancel }}
    </span>
  </button>
  <div class="flex justify-stretch w-full gap-2" *ngIf="text && cancel">
    <button
      [disabled]="disabled"
      [ngClass]="{
        'btn-primary': ctaColorsInverted,
        'btn-secondary': !ctaColorsInverted
      }"
      class="btn items-center grow shrink basis-0 rounded text-md font-semibold"
      (click)="onCancelClick()"
      [class.text-white]="instaButtonDouble"
    >
      <span
        class="text-md font-semibold"
        [ngClass]="{
          'lowercase first-letter:uppercase': firstLetterUppercase,
          capitalize: !firstLetterUppercase
        }"
      >
        {{ cancel }}
      </span>
    </button>
    <button
      [disabled]="disabled"
      [ngClass]="{
        'btn-primary': !ctaColorsInverted,
        'btn-secondary': ctaColorsInverted
      }"
      class="btn items-center grow shrink basis-0 rounded text-md font-semibold"
      (click)="onClick()"
      [class.text-base-100]="instaButtonDouble"
    >
      <span
        class="text-md font-semibold"
        [ngClass]="{
          disabled: disabled,
          'lowercase first-letter:uppercase': firstLetterUppercase,
          capitalize: !firstLetterUppercase
        }"
        >{{ text }}</span
      >
    </button>
  </div>
</div>
<div class="footer noButton" *ngIf="noButton">
  <span>
    {{ text }}
  </span>
</div>
