export enum UserRoles {
  NOT_DEFINED = 0,
  SERVICE_PROVIDER_BASIC = 1,
  SERVICE_PROVIDER_PRO = 2,
  CLIENT_BASIC = 3,
  CLIENT_PRO = 4,
  ESTABLISHMENT_BASIC = 5,
  ESTABLISHMENT_PRO = 6,
  ADMIN = 7,
  AGENCY_BASIC = 8,
  AGENCY_PRO = 9,
  SERVICE_PROVIDER_EXTERNAL = 10,
  ICO = 11,
  CLIENT_PRESS = 12
}
