import { Injectable, NgZone } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  LocationModel,
  TravelLocationModel,
} from '@app/instafeature/models/location';
import {
  AppConfig,
  Config,
  MapInstaStyles,
  MapStyles,
  SummaryLocation,
} from '@app/shared/models';
import { RouteData } from '@app/instafeature/models/route.data';
import { CommonService } from '@app/instafeature/services/common.service';
import { getTimes } from 'suncalc';
import { select, Store } from '@ngrx/store';
import { Direction } from '@app/instafeature/models/direction';
import { DrawModel } from '@app/instafeature/models/draw';
import { isUserClient } from '@app/shared/reducers/user.selectors';
import { getAppConfig } from '@app/shared/reducers/shared.selectors';
import { ConnectType } from '@app/instafeature/models/connect-type';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  public styles: MapStyles | any = new MapStyles();
  public instaStyles: MapInstaStyles = new MapInstaStyles();
  public map: any;
  public centerMap: LocationModel = { latitude: 0, longitude: 0 };
  public profiles: any[] = [];
  public defaultMode: any = 'day';
  public mapStyle: any = this.styles[this.defaultMode];
  public zoom = 8;
  public routeDataListener = new Subject<any>();
  public routeData: RouteData[] = [];
  public durationData: number[] = [0, 0, 0, 0];
  private isStyleSet: boolean = false;
  private config: Config;
  currentUserIsClient;

  constructor(
    private commonService: CommonService,
    private store: Store,
    private zone: NgZone
  ) {
    this.store
      .select(getAppConfig)
      .subscribe((appConfig) => (this.config = appConfig));

    this.store.pipe(select(isUserClient)).subscribe((userClient) => {
      this.currentUserIsClient = userClient;
    });
  }

  public setMap(map: any) {
    this.map = map;
  }

  /**
   * Will set map style according to user time zone (day or night mode)
   */
  public setMapStyle(location: SummaryLocation) {
    if (this.isStyleSet || !location) {
      return;
    }

    const sunTimes = getTimes(
      new Date(),
      location.latitude,
      location.longitude
    );

    if (sunTimes) {
      if (new Date() < sunTimes.sunset && new Date() > sunTimes.sunrise) {
        this.defaultMode = 'day';
      } else {
        this.defaultMode = 'night';
      }
      this.mapStyle = this.styles[this.defaultMode];
    }
  }

  setMapZoom(zoom: number) {
    this.zoom = zoom;
  }

  async fetchDirectionsData(startLocation: any, endLocation: any) {
    const sub = [
      this.commonService.getDirections(
        startLocation,
        endLocation,
        google.maps.TravelMode.DRIVING
      ),
      this.commonService.getDirections(
        startLocation,
        endLocation,
        google.maps.TravelMode.TRANSIT
      ),
      this.commonService.getDirections(
        startLocation,
        endLocation,
        google.maps.TravelMode.BICYCLING
      ),
      this.commonService.getDirections(
        startLocation,
        endLocation,
        google.maps.TravelMode.WALKING
      ),
    ];

    combineLatest(sub).subscribe(
      (val) => {
        this.routeData = [];
        if (val[0]) {
          this.routeData.push({
            type: google.maps.TravelMode.DRIVING,
            data: val[0],
          });
        }
        if (val[1]) {
          this.routeData.push({
            type: google.maps.TravelMode.TRANSIT,
            data: val[1],
          });
        }
        if (val[2]) {
          this.routeData.push({
            type: google.maps.TravelMode.BICYCLING,
            data: val[2],
          });
        }
        if (val[3]) {
          this.routeData.push({
            type: google.maps.TravelMode.WALKING,
            data: val[3],
          });
        }

        this.durationData = [];
        if (val[0]?.routes[0]) {
          this.durationData[0] = val[0].routes[0].legs[0].duration.value;
        }
        if (val[1]?.routes[0]) {
          this.durationData[1] = val[1].routes[0].legs[0].duration.value;
        }
        if (val[2]?.routes[0]) {
          this.durationData[2] = val[2].routes[0].legs[0].duration.value;
        }
        if (sub.length === 4 && val[3]?.routes[0]) {
          this.durationData[3] = val[3].routes[0].legs[0].duration.value;
        }

        this.routeDataListener.next({
          duration: this.durationData,
          route: this.routeData,
        });
      },
      (error) => {
        this.routeDataListener.next(error);
      }
    );
  }

  fetchDirectionsDataByType(
    startLocation: any,
    endLocation: any,
    travelType: any
  ) {
    return this.commonService.getDirections(
      startLocation,
      endLocation,
      travelType
    );
  }

  calculateRouteTimeByType(type: google.maps.TravelMode): Observable<any> {
    return new Observable((observer) => {
      const index = Object.values(google.maps.TravelMode).indexOf(type);
      if (index < this.routeData.length) {
        const date = dayjs().second(this.durationData[index]).toDate();
        observer.next(date);
      } else {
        observer.error('not found');
      }
    });
  }

  createDraw(
    selectedRoute: Direction,
    callType: string
  ): Observable<DrawModel> {
    return new Observable((observer) => {
      let profileMarkerPosition: any;
      let userMarkerPosition: any;
      if (selectedRoute?.data?.routes?.[0].legs) {
        if (
          typeof selectedRoute.data.routes[0].legs[0].start_location.lat ===
          'number'
        ) {
          selectedRoute.data.routes[0].legs[0].start_location =
            new google.maps.LatLng(
              selectedRoute.data.routes[0].legs[0].start_location.lat,
              selectedRoute.data.routes[0].legs[0].start_location.lng
            );
          selectedRoute.data.routes[0].legs[0].end_location =
            new google.maps.LatLng(
              selectedRoute.data.routes[0].legs[0].end_location.lat,
              selectedRoute.data.routes[0].legs[0].end_location.lng
            );
          selectedRoute.data.routes[0].overview_path =
            selectedRoute.data.routes[0].overview_path.map((p: any) => {
              return new google.maps.LatLng(p.lat, p.lng);
            });
        }
        userMarkerPosition =
          callType === ConnectType.INCALL
            ? {
                latitude:
                  selectedRoute.data.routes[0].legs[0].start_location.lat(),
                longitude:
                  selectedRoute.data.routes[0].legs[0].start_location.lng(),
              }
            : {
                latitude:
                  selectedRoute.data.routes[0].legs[0].end_location.lat(),
                longitude:
                  selectedRoute.data.routes[0].legs[0].end_location.lng(),
              };
        profileMarkerPosition =
          callType === ConnectType.INCALL
            ? {
                latitude:
                  selectedRoute.data.routes[0].legs[0].end_location.lat(),
                longitude:
                  selectedRoute.data.routes[0].legs[0].end_location.lng(),
              }
            : {
                latitude:
                  selectedRoute.data.routes[0].legs[0].start_location.lat(),
                longitude:
                  selectedRoute.data.routes[0].legs[0].start_location.lng(),
              };
        observer.next({
          path: null,
          bounds: null,
          pins: {
            userMarkerPosition: userMarkerPosition,
            profileMarkerPosition: profileMarkerPosition,
          },
        });
      } else {
        observer.next();
      }
    });
  }

  getNearestPoint(
    currentLocation: LocationModel,
    points: google.maps.LatLng[]
  ): { point: google.maps.LatLng; distance: number } {
    if (!points?.length) {
      return null;
    }
    let result: { point: google.maps.LatLng; distance: number };
    const currentLatLng = new google.maps.LatLng(
      currentLocation.latitude,
      currentLocation.longitude
    );

    for (const point of points) {
      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        point,
        currentLatLng
      );

      if (!result || result.distance > distance) {
        result = { point, distance };
      }
    }

    return result;
  }

  animateMarkerTo(
    marker: TravelLocationModel,
    newPosition: { latitude: number; longitude: number }
  ) {
    const options = {
      duration: 1000,
      easing: function (x, t, b, c, d) {
        // jquery animation: swing (easeOutQuad)
        return -c * (t /= d) * (t - 2) + b;
      },
    };

    window.requestAnimationFrame =
      window.requestAnimationFrame ||
      (window as any).mozRequestAnimationFrame ||
      (window as any).webkitRequestAnimationFrame ||
      (window as any).msRequestAnimationFrame;
    window.cancelAnimationFrame =
      window.cancelAnimationFrame || (window as any).mozCancelAnimationFrame;

    // save current position. prefixed to avoid name collisions.
    // separate for lat/lng to avoid calling lat()/lng() in every frame
    marker.aTStartPositionLat = marker.latitude;
    marker.aTStartPositionLng = marker.longitude;
    const newPosition_lat = newPosition.latitude;
    let newPosition_lng = newPosition.longitude;

    // crossing the 180° meridian and going the long way around the earth?
    if (Math.abs(newPosition_lng - marker.aTStartPositionLng) > 180) {
      if (newPosition_lng > marker.aTStartPositionLng) {
        newPosition_lng -= 360;
      } else {
        newPosition_lng += 360;
      }
    }

    const animateStep = (
      markerItem: TravelLocationModel,
      startTime: number
    ) => {
      const elapsedTime = new Date().getTime() - startTime;
      const durationRatio = elapsedTime / options.duration; // 0 - 1
      const easingDurationRatio = options.easing(
        durationRatio,
        elapsedTime,
        0,
        1,
        options.duration
      );

      if (durationRatio < 1) {
        this.zone.run(() => {
          markerItem.latitude =
            markerItem.aTStartPositionLat +
            (newPosition_lat - markerItem.aTStartPositionLat) *
              easingDurationRatio;
          markerItem.longitude =
            markerItem.aTStartPositionLng +
            (newPosition_lng - markerItem.aTStartPositionLng) *
              easingDurationRatio;
        });

        // use requestAnimationFrame if it exists on this browser.
        // If not, use setTimeout with ~60 fps
        if (window.requestAnimationFrame) {
          markerItem.aTAnimationHandler = window.requestAnimationFrame(
            function () {
              animateStep(markerItem, startTime);
            }
          );
        } else {
          markerItem.aTAnimationHandler = setTimeout(function () {
            animateStep(markerItem, startTime);
          }, 17);
        }
      } else {
        this.zone.run(() => {
          markerItem.latitude = newPosition.latitude;
          markerItem.longitude = newPosition.longitude;
        });
      }
    };

    // stop possibly running animation
    if (window.cancelAnimationFrame) {
      window.cancelAnimationFrame(marker.aTAnimationHandler);
    } else {
      clearTimeout(marker.aTAnimationHandler);
    }

    animateStep(marker, new Date().getTime());
  }

  public isPointInCircle(
    point: LocationModel,
    center: LocationModel
  ): { isInside: boolean; distance: number } {
    const radius = Number(
      this.config.generalProperties.find(
        (d) => d.name === AppConfig.INSTA_ARRIVED_DISTANCE_METERS
      )?.value
    );
    const pointLatLng = new google.maps.LatLng(point.latitude, point.longitude);
    const centerLatLng = new google.maps.LatLng(
      center.latitude,
      center.longitude
    );
    const distance = google.maps.geometry.spherical.computeDistanceBetween(
      pointLatLng,
      centerLatLng
    );
    return {
      isInside: distance <= radius,
      distance: Number(distance.toFixed(0)),
    };
  }

  public setMapMarkers(spLocation, clientLocation, callType, isClient) {
    const mapMarkers = [
      {
        id: 0,
        isTraveling: true,
        latitude: spLocation.latitude,
        longitude: spLocation.longitude,
      },
      {
        id: 1,
        isTraveling: false,
        latitude: clientLocation.latitude,
        longitude: clientLocation.longitude,
      },
    ];
    if (!isClient && callType === ConnectType.OUTCALL) {
      mapMarkers[0].isTraveling = true;
      mapMarkers[1].isTraveling = false;
    } else if (!isClient && callType === ConnectType.INCALL) {
      mapMarkers[0].isTraveling = true;
      mapMarkers[1].isTraveling = false;
    } else if (isClient && callType === ConnectType.INCALL) {
      mapMarkers[0].isTraveling = false;
      mapMarkers[1].isTraveling = true;
    } else if (isClient && callType === ConnectType.OUTCALL) {
      mapMarkers[0].isTraveling = false;
      mapMarkers[1].isTraveling = true;
    }

    return mapMarkers;
  }
}
