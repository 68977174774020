import * as collection from '../actions/collection.actions';

export interface State {
  loaded: boolean;
  loading: boolean;
  ids: number[];
}

export const initialState: State = {
  loaded: false,
  loading: false,
  ids: [],
};

export function collectionReducer(
  state = initialState,
  action: collection.Actions
): State {
  switch (action.type) {
    case collection.LOAD: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case collection.LOAD_SUCCESS: {
      const profiles = action.payload;

      return {
        loaded: true,
        loading: false,
        ids: profiles.map((profile) => profile.id),
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;

export const getLoading = (state: State) => state.loading;

export const getIds = (state: State) => state.ids;
