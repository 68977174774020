import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { Config, Language } from '@app/shared/models';
import { NgxSliderModule, Options } from 'ngx-slider-v2';
import { Observable, Subject } from 'rxjs';
import { ConnectType } from '@app/instafeature/models/connect-type';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { ServiceTypes } from '@app/instafeature/models/service-types';
import {
  getCurrentServiceProviders,
  getInstaStateSelector,
} from '@app/instafeature/store/instafeature.selectors';
import { Store } from '@ngrx/store';
import { InstafeatureFilterService } from '@app/instafeature/services/instafeature-filter.service';
import {
  getAppConfig,
  getUserLocation,
} from '@app/shared/reducers/shared.selectors';
import { L10N_LOCALE, L10nLocale, L10nTranslatePipe } from 'angular-l10n';
import { FooterButtonComponent } from '../footer-button/footer-button.component';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ExtendedModule } from '@angular/flex-layout/extended';
import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import { LocationSelectorInstaComponent } from '@app/shared/components/location-selector-insta/location-selector-insta.component';
import { Router, RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InstaState } from '@app/instafeature/models/insta-state';
import * as _ from 'lodash-es';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'gingr-find-gingr-filter',
  templateUrl: './find-gingr-filter.component.html',
  styleUrls: ['./find-gingr-filter.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    NgClass,
    ExtendedModule,
    MatCheckboxModule,
    FormsModule,
    MatExpansionModule,
    NgFor,
    NgIf,
    NgxSliderModule,
    L10nTranslatePipe,
    ReactiveFormsModule,
    AsyncPipe,
    SvgIconComponent,
  ],
})
export class FindGingrFilterComponent implements OnInit {
  @Output() hideFilter = new EventEmitter<boolean>();

  priceOptions: Options = {
    floor: 0,
    ceil: 500,
    hideLimitLabels: true,
    hidePointerLabels: false,
  };
  ageOptions: Options = {
    floor: 18,
    ceil: 100,
    showSelectionBar: true,
    hideLimitLabels: true,
    hidePointerLabels: false,
  };
  services: any;
  language: Language[] = [];
  config: Config;
  filterCall = ConnectType;
  servicesType = ServiceTypes;
  resultsCount = 0;
  destroy$ = new Subject();
  expanded = {
    gender: false,
    sexuality: false,
    price: false,
    age: false,
    services: false,
    languages: false,
    hairColor: false,
    ethnicity: false,
    bodyType: false,
    breastSize: false,
    rating: false,
  };
  ratingOptions: Options = {
    floor: 1,
    ceil: 10,
    hideLimitLabels: false,
    hidePointerLabels: false,
  };
  breastSizes = ['A', 'B', 'C', 'D', 'E', 'F+'];
  formGroupTopTypes: FormGroup;
  genderFormGroup: FormGroup;
  sexualityFormGroup: FormGroup;
  servicesFormGroup: FormGroup;
  languagesFormGroup: FormGroup;
  hairColorFormGroup: FormGroup;
  ethnicityFormGroup: FormGroup;
  bodyTypeFormGroup: FormGroup;
  breastSizeFormGroup: FormGroup;
  rating: number = null;
  ageLow = 18;
  ageHigh = 100;
  priceLow = 0;
  priceHigh = 500;
  servicesByCategory = {};
  locationSelected = false;
  bookingAddress$: Observable<string>;
  userLocationAddress$: Observable<string>;
  destroyRef = inject(DestroyRef);
  instaState: InstaState;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public filterService: InstafeatureFilterService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private store: Store,
    private router: Router
  ) {
    iconRegistry.addSvgIconSetInNamespace(
      'services',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/services.stack.svg'
      )
    );

    iconRegistry.addSvgIconSetInNamespace(
      'filters',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/instafeature/icons/filters.stack.svg'
      )
    );
  }

  ngOnInit(): void {
    this.store
      .select(getAppConfig)
      .pipe(takeUntil(this.destroy$))
      .subscribe((config) => {
        this.config = _.cloneDeep(config);

        this.constructFormGroupsAttributes();
      });

    this.store
      .select(getInstaStateSelector)
      .pipe(
        filter((instaState) => !!instaState),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((instaState) => {
        this.instaState = instaState;
        if (!instaState.callType) {
          this.router.navigate(['/instafeature']);
        }
        if (
          instaState.callType === ConnectType.OUTCALL &&
          history.state.selectLocation
        ) {
          this.locationSelected = true;
        }
      });

    this.store
      .select(getCurrentServiceProviders)
      .pipe(
        filter((data) => !!data),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((results) => {
        this.resultsCount = results.length;
      });

    this.bookingAddress$ = this.store
      .select(getInstaStateSelector)
      .pipe(map((insta) => insta.address?.address));

    this.userLocationAddress$ = this.store
      .select(getUserLocation)
      .pipe(map((userLocation) => userLocation?.address));
  }

  expandItem(property: string) {
    if (!this.expanded[property]) {
      Object.keys(this.expanded).forEach((key) => (this.expanded[key] = false));
    }
    this.expanded[property] = !this.expanded[property];
  }

  sortAndFilterServices() {
    this.servicesType.forEach((serviceType: any) => {
      this.servicesByCategory[serviceType.type] = this.config.services
        .filter((s: any) => s.group === serviceType.type)
        .sort((service1, service2) => (service1.name > service2.name ? 1 : -1));
    });
  }

  constructFormGroupTop() {
    const controls = {};
    controls['favourites'] = new FormControl(
      this.filterService.filter.favourites
    );
    controls['new'] = new FormControl(this.filterService.filter.new);
    controls['verified'] = new FormControl(this.filterService.filter.verified);
    controls['video'] = new FormControl(this.filterService.filter.video);
    controls['search'] = new FormControl(this.filterService.filter.search);
    this.formGroupTopTypes = new FormGroup(controls);

    this.formGroupTopTypes.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterService.filter = {
          ...this.filterService.filter,
          favourites: change.favourites,
          new: change.new,
          verified: change.verified,
          video: change.video,
          search: change.search,
        };
        this.filterService.updateSearch();
      });
  }

  constructFormGroupsAttributes() {
    if (this.filterService.filter.ageLow && this.filterService.filter.ageHigh) {
      this.ageHigh = this.filterService.filter.ageHigh;
      this.ageLow = this.filterService.filter.ageLow;
    }
    if (
      this.filterService.filter.priceLow &&
      this.filterService.filter.priceHigh
    ) {
      this.priceHigh = this.filterService.filter.priceHigh;
      this.priceLow = this.filterService.filter.priceLow;
    }
    this.constructFormGroupTop();
    this.constructGenderFormGroup();
    this.constructSexualityFormGroup();
    this.constructBreastFormGroup();
    this.constructHairColorFormGroup();
    this.constructLanguagesFormGroup();
    this.constructBodyTypesFormGroup();
    this.constructOriginFormGroup();
    this.constructServicesFormGroup();
    this.constructSexualityFormGroup();
    this.sortAndFilterServices();
  }

  constructGenderFormGroup() {
    const controls = {};
    this.config.genders.forEach(
      (sex) =>
        (controls[sex.id] = new FormControl(
          this.filterService.filter.gender.findIndex((g) => g === sex.id) > -1
        ))
    );
    this.genderFormGroup = new FormGroup(controls);

    this.genderFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterService.filter = {
          ...this.filterService.filter,
          gender: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => +key),
        };
        this.filterService.updateSearch();
      });
  }

  constructSexualityFormGroup() {
    const controls = {};
    this.config.sexualities.forEach(
      (sexuality) =>
        (controls[sexuality.id] = new FormControl(
          this.filterService.filter.sexuality.findIndex(
            (g) => g === sexuality.id
          ) > -1
        ))
    );
    this.sexualityFormGroup = new FormGroup(controls);

    this.sexualityFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterService.filter = {
          ...this.filterService.filter,
          sexuality: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => +key),
        };
        this.filterService.updateSearch();
      });
  }

  constructServicesFormGroup() {
    const controls = {};
    this.config.services.forEach(
      (service) =>
        (controls[service.id] = new FormControl(
          this.filterService.filter.services.findIndex(
            (g) => g === service.id
          ) > -1
        ))
    );
    this.servicesFormGroup = new FormGroup(controls);

    this.servicesFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterService.filter = {
          ...this.filterService.filter,
          services: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => +key),
        };
        this.filterService.updateSearch();
      });
  }

  constructLanguagesFormGroup() {
    const controls = {};
    this.config.languages = this.config.languages
      .filter((lang) => lang.name !== 'N/A')
      .sort((lang1, lang2) => (lang1.name > lang2.name ? 1 : -1));
    this.config.languages.forEach(
      (language) =>
        (controls[language.id] = new FormControl(
          this.filterService.filter.language.findIndex(
            (g) => g === language.id
          ) > -1
        ))
    );
    this.languagesFormGroup = new FormGroup(controls);

    this.languagesFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterService.filter = {
          ...this.filterService.filter,
          language: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => +key),
        };
        this.filterService.updateSearch();
      });
  }

  constructHairColorFormGroup() {
    const controls = {};
    this.config.hairColors.forEach(
      (hair) =>
        (controls[hair.id] = new FormControl(
          this.filterService.filter.hairColor.findIndex((g) => g === hair.id) >
            -1
        ))
    );
    this.hairColorFormGroup = new FormGroup(controls);

    this.hairColorFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterService.filter = {
          ...this.filterService.filter,
          hairColor: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => +key),
        };
        this.filterService.updateSearch();
      });
  }

  constructOriginFormGroup() {
    const controls = {};
    this.config.origins.forEach(
      (origin) =>
        (controls[origin.id] = new FormControl(
          this.filterService.filter.ethnicity.findIndex(
            (g) => g === origin.id
          ) > -1
        ))
    );
    this.ethnicityFormGroup = new FormGroup(controls);

    this.ethnicityFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterService.filter = {
          ...this.filterService.filter,
          ethnicity: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => +key),
        };
        this.filterService.updateSearch();
      });
  }

  constructBodyTypesFormGroup() {
    const controls = {};
    this.config.bodies.forEach(
      (service) =>
        (controls[service.id] = new FormControl(
          this.filterService.filter.bodyType.findIndex(
            (g) => g === service.id
          ) > -1
        ))
    );
    this.bodyTypeFormGroup = new FormGroup(controls);

    this.bodyTypeFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterService.filter = {
          ...this.filterService.filter,
          bodyType: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => +key),
        };
        this.filterService.updateSearch();
      });
  }

  constructBreastFormGroup() {
    const controls = {};
    this.breastSizes.forEach(
      (size) =>
        (controls[size] = new FormControl(
          this.filterService.filter.breastSize.findIndex((g) => g === size) > -1
        ))
    );
    this.breastSizeFormGroup = new FormGroup(controls);

    this.breastSizeFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterService.filter = {
          ...this.filterService.filter,
          breastSize: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => key),
        };
        this.filterService.updateSearch();
      });
  }

  changeRating() {
    this.filterService.filter.rating = this.rating;
    this.filterService.updateSearch();
  }
  changeAge() {
    this.filterService.filter.ageLow = this.ageLow;
    this.filterService.filter.ageHigh = this.ageHigh;
    this.filterService.updateSearch();
  }

  changePrices() {
    this.filterService.filter.priceLow = this.priceLow;
    this.filterService.filter.priceHigh = this.priceHigh;
    this.filterService.updateSearch();
  }

  search() {
    this.filterService.filter.services = this.services.filter(
      (f: { selected: any }) => f.selected
    );
    this.filterService.updateSearch();
  }

  goToGingrsList() {
    this.router.navigate([
      `/instafeature/find-gingr/${this.instaState.callType.toLowerCase()}`,
    ]);
  }

  resetFilter() {
    this.filterService.resetFilterAndUpdateSearch();
    this.constructFormGroupsAttributes();
  }

  closeFilter() {
    this.filterService.resetFilter();
    this.constructFormGroupsAttributes();
    this.goToGingrsList();
  }

  openMap() {
    this.router.navigate(['/instafeature/select-location'], {
      state: { returnTo: '/instafeature/find-gingr/filter' },
    });
  }

  formHasSelected(formGroup: FormGroup) {
    for (const controlsKey in formGroup.controls) {
      if (formGroup.controls[controlsKey].value) {
        return true;
      }
    }
    return false;
  }
}
