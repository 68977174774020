import { Injectable, ViewContainerRef } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  factoryResolver;
  rootViewContainer;
  dialogRef;
  overlayRef: OverlayRef;

  constructor(public dialog: MatDialog, public overlay: Overlay) {}

  // Use MatDialog
  openDialog(component: any, data?: any, panelClass: string = '') {
    this.dialogRef = this.dialog.open(component, {
      data,
      panelClass,
    });

    return this.dialogRef.afterClosed();
  }

  // Use Overlay
  createModal(component, viewContainer: ViewContainerRef) {
    const config = new OverlayConfig();
    const wWidth = window.screen.width;
    const wHeight = window.screen.height;

    config.positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    config.hasBackdrop = true;
    config.panelClass = 'gOverlay';

    // Create overlay
    this.overlayRef = this.overlay.create(config);
    // Create component portal and attach to overlay

    const componentPortal = new ComponentPortal(component, viewContainer);
    this.overlayRef.attach(componentPortal);

    this.overlayRef.backdropClick().subscribe((e) => {
      componentPortal.detach();
      this.overlayRef.dispose();
      this.overlayRef.detachBackdrop();
      this.overlayRef.detach();
    });
  }
}
