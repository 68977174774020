export const ServiceTypes: any = [
  {
    name: <string>'ACCOUNT.SERVICES.Standard and Sensual',
    type: <number>1,
  },
  {
    name: <string>'ACCOUNT.SERVICES.Kinky and Fetish',
    type: <number>2,
  },
  {
    name: <string>'ACCOUNT.SERVICES.Group and Hardcore',
    type: <number>3,
  },
  {
    name: <string>'ACCOUNT.SERVICES.Extreme and BDSM',
    type: <number>4,
  },
];
