import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom, Observable, of } from 'rxjs';
import { ConfigService } from '@app/shared/services/config.service';
import { ServiceProvidersSearch } from '@app/instafeature/models/service-provider-search';
import { catchError } from 'rxjs/operators';
import { LocationModel } from '@app/instafeature/models/location';
import { ConnectType } from '@app/instafeature/models/connect-type';
import { GeocodingService } from '@app/shared/services';
import { InstaState } from '@app/instafeature/models/insta-state';
import { CreateBookingCommand } from '@app/instafeature/models/create-booking.command';
import { GingrCountingData } from '@app/instafeature/models/gingr-counting-data';
import { Filter } from '@app/instafeature/models/filter';
import { APP_CONFIG } from '@app/shared/models/constants';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private readonly config = inject(APP_CONFIG);
  private readonly geocodingService = inject(GeocodingService);

  headers: HttpHeaders = new HttpHeaders();
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {
    this.headers.append('Content-Type', 'application/json');
  }

  filterGingr(filter: Filter): Observable<ServiceProvidersSearch[]> {
    return this.http
      .post<
        ServiceProvidersSearch[]
      >(`${this.configService.config.apiV3}/instant-booking/gingrs`, filter)
      .pipe(catchError(() => of(null)));
  }

  getGingrCountInArea(location): Observable<GingrCountingData> {
    return this.http
      .post<GingrCountingData>(
        `${this.configService.config.apiV3}/instant-booking/gingrs/count`,
        location
      )
      .pipe(catchError(() => of(null)));
  }

  getClientsCountInArea() {
    return this.http
      .get<number>(
        `${this.configService.config.apiV3}/instant-booking/clients/count`
      )
      .pipe(catchError(() => of(0)));
  }

  getProfile(profileBody): Observable<any> {
    return this.http.post(
      `${this.configService.config.apiV3}/instant-booking/provider`,
      profileBody
    );
  }

  getProfileExtraDetails(profile: any): Observable<any> {
    return this.http.get(
      `${this.configService.config.apiV3}/instant-booking/provider/extra/` +
        profile.id
    );
  }

  closeSession() {
    return this.http
      .post(
        `${this.configService.config.apiV3}/instant-booking/close-session`,
        null
      )
      .pipe(catchError(() => of(null)));
  }

  addReview(body: {
    rate: any;
    text: string;
    revieweeId: number;
    bookingId: number;
  }) {
    return this.http.post(
      `${this.configService.config.apiV3}/instant-booking/review`,
      body
    );
  }

  addBooking(booking: CreateBookingCommand): Observable<InstaState> {
    return this.http
      .post<InstaState>(
        `${this.configService.config.apiV3}/instant-booking`,
        booking
      )
      .pipe(catchError(() => of(null)));
  }

  acceptBooking(
    bookingId: number,
    latitude?: number,
    longitude?: number
  ): Observable<InstaState> {
    return this.http
      .post<InstaState>(
        `${this.configService.config.apiV3}/instant-booking/accept/${bookingId}`,
        { latitude, longitude }
      )
      .pipe(catchError(() => of(null)));
  }

  cancelBooking(
    bookingId: number,
    body: { reason: any; comment: string }
  ): Observable<InstaState> {
    return this.http
      .post<InstaState>(
        `${this.configService.config.apiV3}/instant-booking/cancel/${bookingId}`,
        body
      )
      .pipe(catchError(() => of(null)));
  }

  noResponseBooking(booking_id: number) {
    return this.http
      .post(
        `${this.configService.config.apiV3}/instant-booking/no-response/${booking_id}`,
        null
      )
      .pipe(catchError(() => of(null)));
  }

  confirmArrivalBooking(bookingId: number): Observable<InstaState> {
    return this.http
      .post<InstaState>(
        `${this.configService.config.apiV3}/instant-booking/confirm-arrival/${bookingId}`,
        {}
      )
      .pipe(catchError(() => of(null)));
  }

  startClockBooking(bookingId: number): Observable<InstaState> {
    return this.http
      .post<InstaState>(
        `${this.configService.config.apiV3}/instant-booking/start-clock/${bookingId}`,
        {}
      )
      .pipe(catchError(() => of(null)));
  }

  declineBooking(booking_id: number) {
    return this.http
      .post(
        `${this.configService.config.apiV3}/instant-booking/decline/${booking_id}`,
        null
      )
      .pipe(catchError(() => of(null)));
  }

  pauseBooking(booking_id: any) {
    return this.http
      .post(
        `${this.configService.config.apiV3}/instant-booking/pause/${booking_id}`,
        null
      )
      .pipe(catchError(() => of(null)));
  }

  endBookingEarly(
    bookingId: any,
    body: { reason: any; comment: string }
  ): Observable<InstaState> {
    return this.http
      .post<InstaState>(
        `${this.configService.config.apiV3}/instant-booking/end-early/${bookingId}`,
        body
      )
      .pipe(catchError(() => of(null)));
  }

  restartClockBooking(booking_id: any) {
    return this.http
      .post(
        `${this.configService.config.apiV3}/instant-booking/restart/${booking_id}`,
        null
      )
      .pipe(catchError(() => of(null)));
  }

  checkIntervalsExtend(bookingId: number, serviceProviderId: number) {
    return this.http
      .get(
        `${this.configService.config.apiV3}/instant-booking/provider-free/${serviceProviderId}/${bookingId}`
      )
      .pipe(catchError(() => of(null)));
  }

  requestExtendBooking(
    bookingId: number,
    duration: number,
    extraServices: number[]
  ): Observable<InstaState> {
    return this.http
      .post<InstaState>(
        `${this.configService.config.apiV3}/instant-booking/extend/request/${bookingId}`,
        { duration, extraServices }
      )
      .pipe(catchError(() => of(null)));
  }

  cancelExtendBooking(bookingId: number): Observable<InstaState> {
    return this.http
      .post<InstaState>(
        `${this.configService.config.apiV3}/instant-booking/extend/cancel/${bookingId}`,
        {}
      )
      .pipe(catchError(() => of(null)));
  }

  replyExtendBooking(
    bookingId: number,
    accept: boolean
  ): Observable<InstaState> {
    return this.http
      .post<InstaState>(
        `${this.configService.config.apiV3}/instant-booking/extend/reply/${bookingId}/${accept}`,
        null
      )
      .pipe(catchError(() => of(null)));
  }

  endBooking(booking_id: any) {
    return this.http
      .post(
        `${this.configService.config.apiV3}/instant-booking/end/${booking_id}`,
        null
      )
      .pipe(catchError(() => of(null)));
  }

  getInstaState(): Observable<InstaState> {
    return this.http.get<InstaState>(
      `${this.configService.config.apiV3}/instant-booking`
    );
  }

  async connectUser(
    type: ConnectType,
    location: LocationModel,
    isProvider = false
  ): Promise<any> {
    if (isProvider && type !== ConnectType.INCALL) {
      location = await this.randomizeLocation(location);
    }

    return await firstValueFrom(this.connectUserApiCall(type, location));
  }

  connectUserApiCall(type: ConnectType, location: LocationModel) {
    const data = {
      callType: type,
      latitude: location?.latitude,
      longitude: location?.longitude,
    };
    return this.http.post(
      `${this.configService.config.apiV3}/instant-booking/connect`,
      data
    );
  }

  async randomizeLocation(
    originalLocation: LocationModel
  ): Promise<LocationModel> {
    const geocoderResponse = (
      await this.geocodingService.randomizeLocationInRadius(
        originalLocation.latitude,
        originalLocation.longitude
      )
    )?.results?.[0];

    return {
      latitude: geocoderResponse?.geometry.location.lat(),
      longitude: geocoderResponse?.geometry.location.lng(),
    } as LocationModel;
  }

  getDirections(
    originLocation?: LocationModel,
    destinationLocation?: LocationModel,
    mode?: google.maps.TravelMode | undefined
  ): Observable<any> {
    if (!originLocation || !destinationLocation || !mode) {
      return new Observable((observer) => {
        observer.next(null);
        observer.complete();
      });
    }
    const originMarker = new google.maps.LatLng(
      originLocation.latitude,
      originLocation.longitude
    );
    const destinationMarker = new google.maps.LatLng(
      destinationLocation.latitude,
      destinationLocation.longitude
    );
    const payLoad: google.maps.DirectionsRequest = {
      origin: originMarker,
      destination: destinationMarker,
      avoidTolls: false,
      provideRouteAlternatives: true,
      avoidHighways: false,
      travelMode: mode,
    };

    if (mode === 'TRANSIT') {
      payLoad.transitOptions = {
        departureTime: new Date(),
      };
    }
    const directionsService = new google.maps.DirectionsService();
    return new Observable((observer) => {
      directionsService.route(payLoad, function (response, status) {
        if (status === google.maps.DirectionsStatus.OK) {
          observer.next(response);
        } else {
          observer.next(null);
        }
      });
    });
  }

  createdBookingEvent(
    bookingId: number,
    serviceProviderId: number
  ): Observable<any> {
    return this.http.get(
      `${this.config.wsUrl}/insta/created/${serviceProviderId}/${bookingId}`
    );
  }

  acceptedBookingEvent(bookingId: number, clientId: number): Observable<any> {
    return this.http.get(
      `${this.config.wsUrl}/insta/accepted/${clientId}/${bookingId}`
    );
  }

  declinedBookingEvent(
    bookingId: number,
    clientId: number,
    block = false
  ): Observable<any> {
    return this.http.get(
      `${this.config.wsUrl}/insta/declined/${clientId}/${bookingId}/${block}`
    );
  }

  noResponseBookingEvent(
    bookingId: number,
    serviceProviderId: number
  ): Observable<any> {
    return this.http.get(
      `${this.config.wsUrl}/insta/noResponse/${serviceProviderId}/${bookingId}`
    );
  }

  pauseBookingEvent(bookingId: number, clientId: number): Observable<any> {
    return this.http.get(
      `${this.config.wsUrl}/insta/pause/${clientId}/${bookingId}`
    );
  }

  restartBookingEvent(bookingId: number, clientId: number): Observable<any> {
    return this.http.get(
      `${this.config.wsUrl}/insta/restart/${clientId}/${bookingId}`
    );
  }

  canceledBookingEvent(
    bookingId: number,
    otherUserId: number
  ): Observable<any> {
    return this.http.get(
      `${this.config.wsUrl}/insta/canceled/${otherUserId}/${bookingId}`
    );
  }

  confirmArrivalBookingEvent(
    bookingId: number,
    serviceProviderId: number
  ): Observable<any> {
    return this.http.get(
      `${this.config.wsUrl}/insta/confirm/${serviceProviderId}/${bookingId}`
    );
  }

  startClockBookingEvent(bookingId: number, clientId: number): Observable<any> {
    return this.http.get(
      `${this.config.wsUrl}/insta/start/${clientId}/${bookingId}`
    );
  }

  requestExtendBookingEvent(
    bookingId: number,
    serviceProviderId: number
  ): Observable<any> {
    return this.http.get(
      `${this.config.wsUrl}/insta/request-extend/${serviceProviderId}/${bookingId}`
    );
  }

  cancelExtendBookingEvent(
    bookingId: number,
    serviceProviderId: number
  ): Observable<any> {
    return this.http.get(
      `${this.config.wsUrl}/insta/cancel-extend/${serviceProviderId}/${bookingId}`
    );
  }

  replyExtendBookingEvent(
    bookingId: number,
    serviceProviderId: number,
    accept: boolean
  ): Observable<any> {
    return this.http.get(
      `${this.config.wsUrl}/insta/reply-extend/${serviceProviderId}/${bookingId}/${accept}`
    );
  }

  endBookingEvent(bookingId: number, otherUserId: number): Observable<any> {
    return this.http.get(
      `${this.config.wsUrl}/insta/end/${otherUserId}/${bookingId}`
    );
  }

  getPaymentSlip(transactionId: number, userId: number) {
    return this.http
      .get(
        `${this.configService.config.apiV3}/wallet/payment-slip/${transactionId}/${userId}`,
        { responseType: 'blob' }
      )
      .pipe(catchError(() => of(null)));
  }
}
