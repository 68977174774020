import { inject, Injectable } from '@angular/core';
import { Socket, io } from 'socket.io-client';
import { ConfigService } from '@app/shared/services/config.service';
import { LocationModel } from '@app/instafeature/models/location';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { dialogOptionNoResponse } from '@app/instafeature/models/dialog-data';
import { DIALOG_CONFIG } from '@app/instafeature/models/constraints';
import { DialogOptionComponent } from '@app/instafeature/components/dialog-option/dialog-option.component';
import { filter, take, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {
  getCurrentServiceProviders,
  getInstaStateSelector,
} from '@app/instafeature/store/instafeature.selectors';
import {
  getInstaState,
  saveServiceProviders,
} from '@app/instafeature/store/instafeature.actions';
import { dialogMessages } from '@app/instafeature/models/messages';
import { INSTA_STEP, InstaState } from '@app/instafeature/models/insta-state';
import { getCurrentUser } from '@app/shared/reducers/user.selectors';
import { CurrentUser } from '@app/shared/models/current-user';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { NotificationService } from '@app/shared/services';
import { ToastType } from '@app/shared/models/toast.model';
import { _t } from '@app/shared/helpers';
import { L10nTranslationService } from 'angular-l10n';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  public onBookingAccepted$ = new Subject<any>();
  public onLocationUpdated = new Subject<LocationModel>();
  public unreadMessagesSubject = new Subject<boolean>();
  private readonly translation = inject(L10nTranslationService);
  public notify = inject(NotificationService);
  private currentUser: CurrentUser;
  private socket: Socket<DefaultEventsMap, DefaultEventsMap>;
  private clientDisconnectReason = 'io client disconnect';
  connected = false;
  refreshState = false;
  providerIds: number[] = [];
  serviceProviderUpdateTopicName = 'service_providers_updates';
  readonly serviceProviders = this.store.selectSignal(
    getCurrentServiceProviders
  );
  readonly user = this.store.selectSignal(getCurrentUser);
  readonly instaState = this.store.selectSignal(getInstaStateSelector);
  watchedProvider = null;
  sessionCreatedEventName = 'instafuck_session_created';
  gingrExitedEventName = 'service_provider_exit';

  constructor(
    private configService: ConfigService,
    private matDialog: MatDialog,
    private router: Router,
    private store: Store
  ) {}

  public connectClient(user: CurrentUser) {
    this.currentUser = user;
    if (!this.currentUser?.id) {
      return;
    }
    if (this.socket) {
      if (this.socket?.disconnected) {
        this.socket.connect();
      }
      return;
    }
    this.init();
    this.socket.on(`clients:${this.currentUser.id}`, (arg) => {
      this.onNextMessageClient(arg);
    });
  }

  public connectGingr(user: CurrentUser) {
    this.currentUser = user;
    if (!this.currentUser?.id) {
      return;
    }
    if (this.socket) {
      if (this.socket?.disconnected) {
        this.socket.connect();
      }
      return;
    }
    this.init();
    this.socket.on(`service_providers:${this.currentUser.id}`, (arg) => {
      this.onNextMessageGingr(arg);
    });
  }

  public sendUpdateLocation(location: LocationModel) {
    this.socket.emit('location_updated', {
      ...location,
      userId: this.currentUser.id,
    });
  }

  public sendGingrArrived(location: LocationModel) {
    this.socket.emit('gingr_arrived', {
      ...location,
      userId: this.currentUser.id,
    });
  }

  public serviceProviderExit() {
    this.socket.emit('provider_exit', {
      userId: this.currentUser.id,
    });
  }

  public registerGingrWaitForUpdates(providerIds: number[]) {
    this.removeGingrListeners();
    this.providerIds = providerIds;

    providerIds?.forEach((providerId) => {
      const roomName = `${this.serviceProviderUpdateTopicName}:${providerId}`;
      this.socket.on(roomName, async ({ event }) => {
        if (
          event !== this.gingrExitedEventName &&
          event !== this.sessionCreatedEventName
        ) {
          return;
        } else if (
          providerId === this.watchedProvider &&
          this.instaState().stepId !== INSTA_STEP.ClientOutcallRequested &&
          this.instaState().stepId !== INSTA_STEP.ClientIncallRequested
        ) {
          this.showGingrIsBooked(event);
        } else {
          const providers = this.serviceProviders();
          if (this.router.url.includes('find-gingr') && providers.length > 0) {
            const index = providerIds.findIndex(
              (provider) => provider === providerId
            );
            if (index < 0) {
              return;
            }
            this.store.dispatch(
              saveServiceProviders({
                serviceProviders: [
                  ...providers.slice(0, index),
                  ...providers.slice(index + 1),
                ],
              })
            );
          }
        }
      });
      this.socket.emit('join', { roomName });
    });
  }

  public removeGingrListeners() {
    this.providerIds?.forEach((id) =>
      this.socket.off(`${this.serviceProviderUpdateTopicName}:${id}`)
    );
  }

  public removeCheckGingrBecomedBooked() {
    this.watchedProvider = null;
  }

  public registerGingrBecomesBooked(providerId: number) {
    this.watchedProvider = providerId;
  }

  private showGingrIsBooked(event: string) {
    let title: string;
    let message: string;
    if (event === this.sessionCreatedEventName) {
      message = dialogMessages.gingr.bookedGingr;
      title = 'GINGR IS BOOKED';
    } else if (event === this.gingrExitedEventName) {
      title = 'GINGR EXITED';
      message = dialogMessages.gingr.cancel.exit;
    }
    this.router.navigate(['/instafeature', 'booking', 'finish'], {
      state: {
        message,
        title,
        redirectPath: `/instafeature/find-gingr/${this.instaState().callType.toLowerCase()}`,
        exit: true,
        previousRoute: this.router.url,
      },
    });
  }

  private gingrArrived() {
    this.store.dispatch(getInstaState({ skipRedirect: true }));
    this.store
      .pipe(
        select(getInstaStateSelector),
        filter((instaState: InstaState) => !!instaState),
        take(1)
      )
      .subscribe((instaState: InstaState) => {
        this.router.navigate([`/instafeature/booking/confirm-arrival`]);
      });
  }

  private onNextMessageClient({ event, gingrArrived, latitude, longitude }) {
    if (gingrArrived) {
      this.gingrArrived();
      return;
    } else if (latitude && longitude) {
      this.onLocationUpdated.next({ latitude, longitude } as LocationModel);
      return;
    }
    switch (event) {
      case 'instafuck_session_no_response':
      case 'instafuck_session_confirm_finish_extended':
        this.matDialog.closeAll();
        this.router.navigate([`/`]);
        break;
      case 'instafuck_session_accepted':
        this.store.dispatch(getInstaState({ skipRedirect: true }));
        this.onBookingAccepted$.next(null);
        break;
      case 'instafuck_session_declined':
        this.router.navigate([`/instafeature`, 'booking', 'request-declined']);
        break;
      case 'instafuck_session_declined_no_match':
        const redirectPath =
          '/instafeature/find-gingr/' +
          this.instaState().callType.toLowerCase();
        this.router.navigate([`/instafeature`, 'booking', 'finish'], {
          state: { redirectPath, noMatch: true },
        });
        break;
      case 'instafuck_session_arrival_extended':
        this.matDialog.closeAll();
        this.router.navigate([`/instafeature/find`]);
        break;
      case 'instafuck_session_clock_started':
      case 'instafuck_session_paused':
      case 'instafuck_session_restarted':
        this.store.dispatch(getInstaState({ skipRedirect: false }));
        break;
      case 'instafuck_session_pause_extended':
      case 'instafuck_session_start_clock_extended':
        this.router.navigate(['/instafeature', 'booking', 'finish'], {
          state: {
            message: dialogMessages.gingr.cancel.startClockExceeded,
            title: 'INSTA.date cancelled',
          },
        });
        break;
      case 'instafuck_session_finished_incident':
        this.router.navigate(['/instafeature', 'booking', 'finish'], {
          state: {
            message: 'INSTA.gingr reported incident',
            title: 'INSTA.report incident',
            incident: true,
            isNotReporter: true,
          },
        });
        break;
      case 'instafuck_session_finished':
      case 'instafuck_session_finished_early':
        this.finishedSession();
        break;
      case 'instafuck_session_canceled':
        this.router.navigate(['/instafeature', 'booking', 'finish'], {
          state: {
            message: 'INSTA.gingr cancelled date',
            title: 'INSTA.date cancelled',
            cancel: true,
            isNotReporter: true,
          },
        });
        break;
      case 'instafuck_session_gingr_arrived':
        this.store.dispatch(getInstaState({ skipRedirect: true }));
        this.store
          .pipe(
            select(getInstaStateSelector),
            filter((instaState: InstaState) => !!instaState),
            take(1)
          )
          .subscribe((instaState: InstaState) => {
            this.router.navigate([`/instafeature/booking/confirm-arrival`]);
          });
        break;
      case 'instafuck_session_extension_accepted':
        this.router.navigate([`/instafeature/booking/extend-confirmation`], {
          state: { accepted: true },
        });
        this.store.dispatch(getInstaState({ skipRedirect: true }));
        break;
      case 'instafuck_session_extension_declined':
        this.router.navigate([`/instafeature/booking/extend-confirmation`], {
          state: { accepted: false },
        });
        this.store.dispatch(getInstaState({ skipRedirect: true }));
        break;
      case 'unread_messages':
        this.unreadMessagesSubject.next(true);
        break;
    }
  }

  private finishedSession() {
    this.store
      .pipe(
        select(getInstaStateSelector),
        filter((instaState: InstaState) => !!instaState),
        take(1)
      )
      .subscribe(() => {
        this.router.navigate(['/instafeature', 'booking', 'rate']);
      });
  }

  private onNextMessageGingr({ event, latitude, longitude }) {
    if (latitude && longitude) {
      this.onLocationUpdated.next({ latitude, longitude } as LocationModel);
      return;
    }
    switch (event) {
      case 'instafuck_session_created':
        this.store.dispatch(getInstaState({ skipRedirect: false }));
        break;
      case 'instafuck_session_canceled':
        this.router.navigate(['/instafeature', 'booking', 'finish'], {
          state: {
            message:
              this.instaState().state === 'Pending'
                ? 'INSTA.Booking request cancelled message'
                : 'INSTA.client cancelled date',
            title:
              this.instaState().state === 'Pending'
                ? 'INSTA.Booking request cancelled'
                : 'INSTA.date cancelled',
            cancel: true,
            isNotReporter: true,
            redirectPath:
              this.instaState().state === 'Pending'
                ? `/instafeature/find-client/${this.instaState().callType}`
                : '/gingrs',
          },
        });
        this.store.dispatch(getInstaState({ skipRedirect: true }));
        break;
      case 'instafuck_session_arrival_extended':
        this.router.navigate(['/instafeature', 'booking', 'finish'], {
          state: {
            message: dialogMessages.client.cancel.estimatedArrivalExceeded,
            title: 'INSTA.date cancelled',
          },
        });
        break;
      case 'instafuck_session_start_clock_extended':
        this.matDialog.closeAll();
        this.router.navigate([`/instafeature/find-client`]);
        break;
      case 'instafuck_session_confirm_arrival_extended':
        this.router.navigate(['/instafeature', 'booking', 'finish'], {
          state: {
            message: dialogMessages.client.cancel.confirmArrivalExceeded,
            title: 'INSTA.date cancelled',
          },
        });
        break;
      case 'instafuck_session_pause_extended':
        this.router.navigate(['/instafeature', 'booking', 'finish'], {
          state: {
            message: dialogMessages.gingr.cancel.startClockExceeded,
            title: 'INSTA.date cancelled',
          },
        });
        break;
      case 'instafuck_session_confirm_finish_extended':
        this.router.navigate(['/instafeature', 'booking', 'finish'], {
          state: {
            message: dialogMessages.gingr.finished.confirmExtended,
            title: 'INSTA.date ended',
          },
        });
        break;
      case 'instafuck_session_no_response':
        this.matDialog.closeAll();
        this.router.navigate(['/instafeature', 'no-response'], {
          state: { accept: true },
        });
        break;
      case 'instafuck_session_arrived':
        this.store.dispatch(getInstaState({ skipRedirect: false }));
        break;
      case 'instafuck_session_extended':
        this.store.dispatch(getInstaState({ skipRedirect: true }));
        this.router.navigate(['/instafeature', 'booking', 'extend-gingr']);
        break;
      case 'instafuck_session_finished':
      case 'instafuck_session_finished_early':
        this.finishedSession();
        break;
      case 'instafuck_session_finished_incident':
        this.router.navigate(['/instafeature', 'booking', 'finish'], {
          state: {
            message: 'INSTA.client reported incident',
            title: 'INSTA.report incident',
            incident: true,
            isNotReporter: true,
          },
        });
        break;
      case 'unread_messages':
        this.unreadMessagesSubject.next(true);
        break;
      case 'instafuck_session_extension_cancelled':
        this.notify.addToast({
          message: this.translation.translate(
            _t('INSTA.The client cancelled the extension')
          ),
          timeout: 2000,
          type: ToastType.Info,
        });
        this.store.dispatch(getInstaState({ skipRedirect: false }));
    }
  }

  private init(): void {
    this.socket = io(this.configService.config.socketIo);
    this.refreshState = false;

    this.socket.on('connect', () => {
      this.connected = true;
      this.socket.emit('login', { userId: this.currentUser.id });
      if (this.refreshState) {
        this.refreshState = false;
        this.store.dispatch(getInstaState({ skipRedirect: false }));
      }
    });

    this.socket.on('disconnect', (args) => {
      if (args === this.clientDisconnectReason) {
        this.connected = false;
      } else {
        this.refreshState = true;
      }
    });

    this.socket.on('connect_error', () => {
      setTimeout(() => {
        this.socket.connect();
      }, 3000);
    });
  }

  close() {
    this.socket?.disconnect();
  }
}
