import { filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getCurrentUser, getUser } from '@app/shared/reducers/user.selectors';
import { User } from '@app/shared/models';
import { CurrentUser } from '@app/shared/models/current-user';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router, private store: Store<any>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(getCurrentUser),
      filter((user) => user !== undefined),
      map((user: CurrentUser) => {
        if (!!user) {
          return true;
        } else {
          this.router.navigate(['/welcome']);
          return false;
        }
      })
    );
  }
}
