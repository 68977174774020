import { inject, Injectable } from '@angular/core';
import { ConfigModel } from '@app/shared/models/config.model';
import { APP_CONFIG } from '@app/shared/models/constants';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  readonly config: ConfigModel = inject(APP_CONFIG);
}
