import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MobileBottomNavigationItem } from '@app/shared/models/mobile-bottom-navigation-item.model';

@Injectable({
  providedIn: 'root',
})
export class MobileBottomNavigationService {
  private showMobileSubject = new BehaviorSubject<MobileBottomNavigationItem>({
    showMobileBottomMenu: true,
    url: '',
  });
  public showMobileHelper = this.showMobileSubject.asObservable();
  constructor() {}

  public setShowValue(value: MobileBottomNavigationItem) {
    this.showMobileSubject.next(value);
  }
}
