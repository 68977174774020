import { Inject, Injectable } from '@angular/core';
import { L10nConfig, L10nProvider, L10nTranslationLoader } from 'angular-l10n';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TranslationLoader implements L10nTranslationLoader {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  public get(
    language: string,
    provider: L10nProvider
  ): Observable<{ [key: string]: any }> {
    const url = `${provider.asset}${language}.json?d=${Date.now()}`;
    const options = {
      headers: this.headers,
      params: new HttpParams().set('v', provider.options.version),
    };
    return this.http.get(url, options);
  }
}

export const l10nConfig: L10nConfig = {
  format: 'language-region',
  providers: [
    {
      name: 'app',
      asset: `/assets/l10n/`,
      options: { version: 1.0 },
    },
  ],
  cache: false,
  keySeparator: '.',
  defaultLocale: {
    language: 'en',
    currency: 'EUR',
  },
  schema: [
    { locale: { language: 'en', currency: 'EUR' } },
    { locale: { language: 'de', currency: 'EUR' } },
    { locale: { language: 'bg', currency: 'EUR' } },
    { locale: { language: 'es', currency: 'EUR' } },
    { locale: { language: 'fr', currency: 'EUR' } },
    { locale: { language: 'hu', currency: 'EUR' } },
    { locale: { language: 'it', currency: 'EUR' } },
    { locale: { language: 'nl', currency: 'EUR' } },
    { locale: { language: 'pt', currency: 'EUR' } },
    { locale: { language: 'ro', currency: 'EUR' } },
    { locale: { language: 'ru', currency: 'EUR' } },
    { locale: { language: 'th', currency: 'EUR' } },
  ],
};
